import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { DropZone } from '../../components/DropZone';
import {
  useCreateVideo,
  CreateVideoResponse,
} from '../../queries/useCreateVideo';

interface Props {
  onComplete(response: [CreateVideoResponse, File]): void;
  onError(error: AxiosError): void;
}

export function CreateVideo({ onComplete, onError }: Props) {
  const [file, setFile] = useState<File | null>(null);
  const { isLoading, data, error } = useCreateVideo(file);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (error) {
      onError(error);
      return;
    }

    if (!data || !file) {
      return;
    }

    onComplete([data, file]);
  }, [isLoading, data, file, error, onComplete, onError]);

  if (!file) {
    return <DropZone isLoading={isLoading} onDrop={setFile} />;
  }

  return <div>Creating video</div>;
}

import { ReactElement } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Video } from '../components/Video';
import { NotFoundException } from '../exceptions/NotFoundException';
import { useGetVideo } from '../queries/useGetVideo';
import { InfoEntry } from './ScreenVideo/InfoEntry';

type RouteParams = 'videoId';

export function ScreenVideo() {
  const { videoId } = useParams<RouteParams>();
  const { isLoading, data, error } = useGetVideo(false, videoId);

  if (!videoId) {
    return <Navigate to={'/404'} />;
  }

  if (isLoading) {
    return <div>Loading {videoId}</div>;
  }

  if (error) {
    if (error instanceof NotFoundException) {
      return <div>Video could not be found</div>;
    } else {
      return <div>There was an error getting the video</div>;
    }
  }

  if (!data) {
    throw new Error('Is not loading no error and no data');
  }

  let videoPlayer: ReactElement;

  if (data.status === 'published' || data.status === 'unpublished') {
    videoPlayer = (
      <Video
        status={data.status}
        playbackId={data.playbackIdPublic}
        poster={data.previewImageLarge}
      />
    );
  } else {
    videoPlayer = <Video status={data.status} />;
  }

  return (
    <div className="m-4 mt-8 flex flex-col gap-4 md:flex-row">
      {/* Note in the following translate-x-0 is needed for Safari to render rounded corners */}
      <div className="h-max translate-x-0 overflow-hidden rounded-lg md:w-3/4">
        {videoPlayer}
      </div>
      <div className="pt-6 md:w-1/4 md:pt-0">
        <InfoEntry
          videoId={videoId}
          status={data.status}
          publishDate={data.publishDate}
          title={data.title}
          tags={data.tags}
          description={data.description}
        />
      </div>
    </div>
  );
}

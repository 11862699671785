import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import {
  getStorage as fireBaseGetStorage,
  connectStorageEmulator,
} from 'firebase/storage';
import { getStripePayments } from '@stripe/firestore-stripe-payments';

const firebaseConfig = {
  apiKey: 'AIzaSyCKCOZxVDyJJ0ydwo-MfaxYDd1vgAqLelk',
  authDomain: 'creatorcamp.firebaseapp.com',
  projectId: 'creatorcamp',
  storageBucket: 'creatorcamp.appspot.com',
  messagingSenderId: '677332621330',
  appId: '1:677332621330:web:2208965764ea6326e820cb',
  measurementId: 'G-06YF0NB533',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const stripePayments = getStripePayments(app, {
  productsCollection: 'products',
  customersCollection: 'customers',
});

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9099');

  const db = getFirestore();
  connectFirestoreEmulator(db, 'localhost', 3333);
}

function getStorage(bucketName: string) {
  const storage = fireBaseGetStorage(app, `gs://${bucketName}`);

  if (process.env.NODE_ENV === 'development') {
    connectStorageEmulator(storage, 'localhost', 9199);
  }

  return storage;
}

export { getStorage, stripePayments };

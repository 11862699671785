import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { getApiUrl } from '../utils/getUrl';
import { useApiHeaders } from './useApiHeaders';
import { Video } from '../@types/video';

export function queryKey(afterId?: string) {
  const key = 'videos';

  return afterId ? [key, { afterId }] : key;
}

export function useGetVideos(afterId?: string) {
  const headers = useApiHeaders();

  const url = afterId
    ? getApiUrl(`/videos`, { afterId })
    : getApiUrl(`/videos`);

  const { isLoading, isIdle, data, error } = useQuery<Video[], AxiosError>(
    queryKey(afterId),
    async () => {
      const { data, status } = await axios.get<Video[]>(url, {
        headers,
      });

      if (status === 404) {
        throw new Error('not found');
      }

      return data;
    },
    {
      enabled: Boolean(headers),
    },
  );

  return {
    isLoading: isIdle || isLoading,
    data,
    error,
  };
}

import { useEffect } from 'react';
import { useUploadVideo } from '../../queries/useUploadVideo';

interface Props {
  file: File;
  bucketName: string;
  filePath: string;
  onComplete(): void;
  onError(error: Error): void;
}

export function UploadVideo({
  file,
  bucketName,
  filePath,
  onComplete,
  onError,
}: Props) {
  const { isLoading, error } = useUploadVideo({
    file,
    bucketName,
    filePath,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (error) {
      onError(error);
    }

    onComplete();
  }, [isLoading, error, onComplete, onError]);

  return <></>;
}

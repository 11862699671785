import { LandingFeed } from '../components/LandingFeed';
import { SkeletonLandingFeed } from '../components/SkeletonLandingFeed';
import { useGetLanding } from '../queries/useGetLanding';

function Skeleton() {
  return (
    <div className="pt-4">
      <SkeletonLandingFeed />
      <SkeletonLandingFeed />
      <SkeletonLandingFeed />
    </div>
  );
}

export function ScreenLanding() {
  const { isLoading, data, error } = useGetLanding();

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <div>There was an error loading the landing page</div>;
  }

  if (!data) {
    return (
      <div>There was an error loading the landing page. No data was loaded</div>
    );
  }

  return (
    <>
      {data.map((feed, i) => {
        return (
          <div key={`feed-${i}`} className="pt-4">
            <LandingFeed {...feed} />
          </div>
        );
      })}
    </>
  );
}

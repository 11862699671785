import {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  validate,
  Length,
  IsEmail,
  IsNotEmpty,
  ValidationError,
  MaxLength,
} from 'class-validator';
import { plainToClass } from 'class-transformer';
import { User } from '../@types/user';
import { Button } from '../components/Button';
import { TextInput } from '../components/TextInput';
import { Toggle } from '../components/Toggle';
import { useGetProfile } from '../queries/useGetProfile';
import { useUpdateProfile } from '../queries/useUpdateProfile';
import { BadRequestException } from '../exceptions/BadRequestException';
import { ValidationResponse } from '../@types/validation-errors';
import { usePopup } from '../contexts/PopUpContext';
import { useUser } from '../contexts/UserContext';
import { ProfileImageInput } from '../components/ProfileImageInput';
import { SkeletonScreenEditProfile } from './ScreenEditProfile/SkeletonScreenProfile';
import { useUpdateProfileImage } from '../queries/useUpdateProfileImage';
import { useToast } from '../components/ToastContainer';
import { TextArea } from '../components/TextArea';
import { ChannelDetails } from './ScreenEditProfile/ChannelDetails';
import { UrlErrorToast } from '../components/UrlErrorToast';

type FormState = Pick<
  User,
  | 'displayName'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'canMarket'
  | 'photoURL'
  | 'description'
>;
type KeyOfFormState = keyof FormState;
type FormErrors = {
  [key in KeyOfFormState]: { value: string; message: string };
};

class FormValidator implements Omit<FormState, 'canMarket'> {
  @IsNotEmpty({
    message: 'Username cannot be empty',
  })
  @Length(3, 30, {
    message: 'Must between 3 and 20 charachters',
  })
  displayName: string;

  @MaxLength(300, {
    message: 'Must be 300 charachters or less',
  })
  description: string;

  @IsNotEmpty({
    message: 'Email cannot be empty',
  })
  @IsEmail(undefined, { message: "Email isn't a proper email" })
  email: string;

  @IsNotEmpty({
    message: 'First Name cannot be empty',
  })
  firstName: string;

  @IsNotEmpty({
    message: 'Last Name cannot be empty',
  })
  lastName: string;
}

function getHasChanged(
  currentValues: FormState | undefined,
  savedFormState: FormState | undefined,
) {
  if (!currentValues) {
    return false;
  }

  if (!savedFormState) {
    return false;
  }

  if (!savedFormState) {
    return true;
  }

  return (Object.keys(savedFormState) as KeyOfFormState[]).reduce(
    (hasChanged, key) => {
      if (hasChanged) return true;

      return currentValues[key] !== savedFormState[key];
    },
    false,
  );
}

function convertValidationErrorsToFormErrors(validations: ValidationError[]) {
  return validations.reduce((formErrors, validation) => {
    const key = validation.property as KeyOfFormState;

    formErrors[key] = {
      value: validation.value,
      message: validation.constraints
        ? Object.entries(validation.constraints)[0][1]
        : 'Is not valid',
    };

    if (key === 'canMarket') {
      throw new Error('Should not validate canMarket');
    }

    return formErrors;
  }, {} as any);
}

function convertServerErrorResponseToFormErrors(response: ValidationResponse) {
  return (Object.keys(response.message) as KeyOfFormState[]).reduce(
    (newFormErrors, responseKey) => {
      const { value, errors } = response.message[responseKey];

      if (responseKey === 'canMarket') {
        throw new Error('server should not fail on canMarket');
      }

      newFormErrors[responseKey] = { value, message: errors[0] };

      return newFormErrors;
    },
    {} as FormErrors,
  );
}

function getFormValues(form: HTMLFormElement) {
  return Array.prototype.slice
    .apply(form.elements)
    .filter((element) => Boolean(element.name))
    .reduce((values, element) => {
      values[element.name] = element.value;

      return values;
    }, {});
}

export function ScreenEditProfile() {
  const { showPopUp } = usePopup();
  const [savedFormState, setSavedFormState] = useState<FormState | undefined>(
    undefined,
  );
  const [formState, setFormState] = useState<FormState | undefined>(undefined);
  const [formErrors, setFormErrors] = useState<FormErrors | undefined>(
    undefined,
  );
  const [profileImageToUpload, setProfileImageToUpload] = useState<File | null>(
    null,
  );

  const { isLoading: isUserLoading, signOut } = useUser();
  const { isLoading: isProfileLoading, error, data } = useGetProfile();
  const { isLoading: isUpdatingProfile, update } = useUpdateProfile();
  const { isLoading: isUpdatingProfileImage, updateProfileImage } =
    useUpdateProfileImage(profileImageToUpload);
  const { addToast } = useToast();
  const isUpdating = isUpdatingProfile || isUpdatingProfileImage;
  const formRef = useRef<HTMLFormElement>(null);

  const hasChangedForm = getHasChanged(formState, savedFormState);
  const hasChangedEmail = formState?.email !== savedFormState?.email;
  const hasChangedProfileImage = profileImageToUpload !== null;

  useEffect(() => {
    if (!data) return;

    const { displayName, canMarket, email, firstName, lastName, description } =
      data;

    setSavedFormState({
      displayName,
      canMarket,
      email,
      firstName,
      lastName,
      description,
    });
  }, [data]);

  const updateErrors = useCallback(
    (newFormErrors: FormErrors) => {
      if (!formState) {
        throw new Error('should never update errors when no formState exists');
      }

      const newFormState: FormState = { ...formState };

      (Object.keys(newFormErrors) as KeyOfFormState[]).forEach((key) => {
        if (key === 'canMarket') {
          throw new Error('validation should never happen for canMarket');
        }

        newFormState[key] = '';
      });

      setFormState(newFormState as FormState);
      setFormErrors(newFormErrors);
    },
    [formState],
  );

  const doSave = useCallback<(isUpdatingEmail: boolean) => void>(
    async (isUpdatingEmail: boolean) => {
      if (!hasChangedForm || !savedFormState || !formState) {
        return;
      }

      if (!hasChangedForm) {
        return;
      }

      try {
        const { photoURL, ...formStateMinusPhotoURL } = formState;

        await update(formStateMinusPhotoURL);

        setSavedFormState((currentFormState) => {
          return {
            ...currentFormState,
            ...formState,
          };
        });

        if (isUpdatingEmail && signOut) {
          signOut();
        }
      } catch (error) {
        if (error instanceof BadRequestException && error.response) {
          updateErrors(convertServerErrorResponseToFormErrors(error.response));
          return;
        }

        throw error;
      }
    },
    [hasChangedForm, savedFormState, formState, update, signOut, updateErrors],
  );

  const doResetEmail = useCallback<() => void>(() => {
    if (!savedFormState) {
      return;
    }

    const { email } = savedFormState;

    setFormState((formState) => {
      if (!formState) {
        return formState;
      }

      return {
        ...formState,
        email,
      };
    });
  }, [savedFormState]);

  const handleOnSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (ev) => {
      ev.preventDefault();

      if (hasChangedProfileImage) {
        try {
          const photoURL = await updateProfileImage();

          setProfileImageToUpload(null);
          setSavedFormState((currentFormState) => {
            return {
              ...currentFormState!,
              photoURL,
            };
          });
        } catch (error) {
          if (error instanceof Error) {
            addToast({ type: 'error', message: error.message });
          } else {
            throw error;
          }
        }
      }

      if (!hasChangedForm || !savedFormState || !formState) {
        return;
      }

      if (
        formState.canMarket !== 'accepts_marketing' &&
        formState.canMarket !== 'rejects_marketing'
      ) {
        throw new Error('invalid value for canMarket');
      }

      const validations = await validate(
        plainToClass(FormValidator, formState),
      );

      if (validations.length > 0) {
        updateErrors(convertValidationErrorsToFormErrors(validations));
        return;
      }

      if (hasChangedEmail) {
        showPopUp({
          type: 'confirm',
          message: (
            <>
              <p>Hey. Sorry to interrupt...</p>
              <p className="pt-2">
                As a security precaution when you change your email we'll need
                to sign you out. After your email has been changed you can sign
                back in.
              </p>
              <p className="pt-2">Are you ok with us signing you out now?</p>
            </>
          ),
          async onOk() {
            doSave(true);
          },
          onCancel: doResetEmail,
        });
        return;
      }

      doSave(false);
    },
    [
      hasChangedProfileImage,
      hasChangedForm,
      savedFormState,
      formState,
      hasChangedEmail,
      doSave,
      updateProfileImage,
      addToast,
      updateErrors,
      showPopUp,
      doResetEmail,
    ],
  );

  const handleFormChange = useCallback(() => {
    const values = getFormValues(formRef.current!);

    const { displayName, canMarket, email, firstName, lastName, description } =
      values;

    setFormState((currentFormState) => {
      const formPhotoUrl = currentFormState?.photoURL;
      const dataPhotoUrl = data?.photoURL;
      const photoURL = formPhotoUrl || dataPhotoUrl;

      return {
        displayName,
        canMarket,
        email,
        firstName,
        lastName,
        photoURL,
        description,
      };
    });

    if (!formErrors) {
      return;
    }

    const newFormErrors = (Object.keys(formErrors) as KeyOfFormState[]).reduce(
      (newFormErrors, key) => {
        if (values[key] !== formErrors[key].value) {
          delete newFormErrors[key];
        }

        return newFormErrors;
      },
      { ...formErrors },
    );

    setFormErrors(newFormErrors);
  }, [formErrors, data]);

  const onProfileImage = useCallback<(file: File) => void>(
    (file: File) => {
      setProfileImageToUpload(file);
      setFormState((formState) => {
        if (!formState) {
          return formState;
        }

        return {
          ...formState,
          photoURL: URL.createObjectURL(file),
        };
      });
    },
    [setFormState],
  );

  if (isProfileLoading || isUserLoading) {
    return <SkeletonScreenEditProfile />;
  }

  if (error) {
    return <div>There was an error getting your profile</div>;
  }

  if (!data) {
    return <div>There was an error getting your profile data</div>;
  }

  const {
    email,
    description = '',
    displayName = '',
    canMarket = '',
    firstName = '',
    lastName = '',
    photoURL,
  } = formState ? formState : data;

  return (
    <>
      <UrlErrorToast />
      <div className="flex max-w-screen-lg flex-col gap-8 p-4 md:flex-row">
        <div className="flex w-full justify-center md:w-2/12">
          <div className="w-1/3 md:w-full">
            <ProfileImageInput
              disabled={isUpdating}
              profileImageUrl={photoURL}
              onFile={onProfileImage}
            />
          </div>
        </div>
        <div className="flex-grow">
          <form
            ref={formRef}
            onSubmit={handleOnSubmit}
            onChange={handleFormChange}
          >
            <div>
              <TextInput
                name="displayName"
                label="Display Name"
                placeholder="Display Name"
                error={formErrors?.displayName?.message}
                maxLength={30}
                showMaxLengthPercentage={0.5}
                value={displayName}
                disabled={isUpdating}
              />
            </div>
            <div className="mt-4">
              <TextArea
                name="description"
                label="Description"
                maxLength={300}
                value={description}
              />
            </div>
            <div className="mt-4">
              <TextInput
                name="email"
                label="Email"
                placeholder="Email"
                error={formErrors?.email?.message}
                value={email}
                disabled={isUpdating}
              />
            </div>
            <div className="mt-4">
              <TextInput
                name="firstName"
                label="First Name"
                placeholder="First Name"
                error={formErrors?.firstName?.message}
                maxLength={25}
                showMaxLengthPercentage={0.5}
                value={firstName}
                disabled={isUpdating}
              />
            </div>
            <div className="mt-4">
              <TextInput
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                error={formErrors?.lastName?.message}
                maxLength={25}
                showMaxLengthPercentage={0.5}
                value={lastName}
                disabled={isUpdating}
              />
            </div>
            <div className="mt-6 md:mt-4">
              <Toggle
                name="canMarket"
                label="Marketing"
                accessibility="Accepts Marketing"
                value={canMarket === 'accepts_marketing'}
                onValue={'accepts_marketing'}
                offValue={'rejects_marketing'}
                disabled={isUpdating}
                onChange={() => {
                  handleFormChange();
                }}
              />
            </div>
            <div className="mt-4 flex justify-end">
              <Button
                disabled={!hasChangedForm && !hasChangedProfileImage}
                isLoading={isUpdating}
              >
                Save Profile
              </Button>
            </div>
          </form>

          <div className="mt-4">
            <ChannelDetails channelId={data?.youtubeChannelId} />
          </div>
        </div>
      </div>
    </>
  );
}

import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getApiUrl } from '../utils/getUrl';
import { useApiHeaders } from './useApiHeaders';

interface CreateVideo {
  fileName: string;
}

export interface CreateVideoResponse {
  status: 'created';
  id: string;
  bucketName: string;
  filePath: string;
  uid: string;
  publishDate: null;
}

export function useCreateVideo(file: File | null) {
  const headers = useApiHeaders();
  const { isLoading, data, error } = useQuery<CreateVideoResponse, AxiosError>(
    'create_video',
    async () => {
      if (!file) {
        throw new Error('File not set');
      }

      const video: CreateVideo = {
        fileName: file.name,
      };

      const response = await axios.post(getApiUrl('/videos/create'), video, {
        headers,
      });

      return response.data as CreateVideoResponse;
    },
    {
      enabled: Boolean(headers && file),
    },
  );

  return {
    isLoading,
    data,
    error,
  };
}

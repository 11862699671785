import { VideoStatus as VideoStatusType } from '../@types/VideoStatus';
import { classNames } from '../utils/classNames';

interface Props {
  status: VideoStatusType;
}

export function VideoStatus({ status }: Props) {
  return (
    <div
      className={classNames(
        'leading-sm flex select-none rounded-lg p-2 text-xs font-bold',
        {
          'bg-orange-400 text-orange-200': status === 'created',
          'bg-teal-500 text-teal-300': status === 'processing',
          'bg-red-500 text-red-300': status === 'processing_error',
          'bg-blue-500 text-blue-300': status === 'unpublished',
          'bg-green-500 text-green-300': status === 'published',
        },
      )}
    >
      {status}
    </div>
  );
}

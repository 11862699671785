import { useUser } from '../contexts/UserContext';
import { Logo } from './Logo';
import { URL_PROD, URL_LOCAL } from '../settings';
import { UserMenu } from './UserMenu';
import { useLocation, useNavigate } from 'react-router';
import { Spinner } from './Spinner';

function login(redirect: string) {
  let loginUrl: string;

  if (process.env.NODE_ENV === 'production') {
    loginUrl = `${URL_PROD}/auth?state=${JSON.stringify({
      site: 'admin',
      redirect,
    })}`;
  } else {
    loginUrl = `${URL_LOCAL}/app/auth?state=${JSON.stringify({
      site: 'admin',
      redirect,
    })}`;
  }

  window.location.assign(loginUrl);
}

export function TobBar() {
  const { isLoggedIn, isLoading } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = location.pathname.substring(1);

  return (
    <div className="flex h-16 items-center justify-between border-b border-b-slate-400 bg-slate-50 px-4 md:h-20">
      <div className="w-1/3"></div>

      <div className="flex w-1/3 justify-center">
        <button
          onClick={() => {
            navigate('/');
          }}
        >
          <Logo />
        </button>
      </div>

      <div className="flex w-1/3 justify-end">
        {isLoggedIn ? (
          <UserMenu />
        ) : isLoading ? (
          <div className="h-6 w-6">
            <Spinner />
          </div>
        ) : (
          <div
            className="cursor-pointer text-sm font-semibold"
            onClick={() => {
              login(redirect);
            }}
          >
            SIGN IN
          </div>
        )}
      </div>
    </div>
  );
}

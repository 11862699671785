import {
  FormEventHandler,
  InputHTMLAttributes,
  useCallback,
  useState,
} from 'react';
import { classNames } from '../utils/classNames';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  showMaxLengthPercentage?: number;
}

function getLength(
  value: string | number | readonly string[] | undefined,
): number {
  return value === undefined || typeof value === 'number' ? 0 : value.length;
}

export function TextInput(props: Props) {
  const {
    label,
    name,
    value,
    error,
    maxLength,
    onInput,
    showMaxLengthPercentage,
    ...restProps
  } = props;
  const hasMaxLength = maxLength !== undefined;
  const [currentLength, setCurrentLength] = useState<number>(getLength(value));

  if (label && !name) {
    throw new Error('if using label you need to pass a name');
  }

  if (
    showMaxLengthPercentage !== undefined &&
    (showMaxLengthPercentage < 0 || showMaxLengthPercentage > 1)
  ) {
    throw new Error('showMaxLengthPercentage must be between 0-1');
  }

  const handleInput = useCallback<FormEventHandler<HTMLInputElement>>(
    (ev) => {
      if (hasMaxLength) {
        setCurrentLength(getLength(ev.currentTarget.value));
      }

      if (onInput) {
        onInput(ev);
      }
    },
    [onInput, hasMaxLength],
  );

  return (
    <div className="flex w-full flex-col justify-between md:flex-row md:items-center">
      {label && (
        <label className="md:w-32" htmlFor={name}>
          {label}
        </label>
      )}
      <div
        className={classNames(
          'flex grow items-center justify-between rounded-md p-2 text-sm shadow-inner focus-visible:bg-slate-600',
          {
            'bg-slate-100 shadow-slate-200': !error,
            'bg-red-700 shadow-red-800': Boolean(error),
          },
        )}
      >
        <input
          {...restProps}
          value={value}
          name={name}
          type="text"
          placeholder={error || props.placeholder}
          className={classNames(
            'grow p-0 pr-1',
            'bg-transparent ',
            'border-transparent focus:border-transparent focus:ring-0',
            {
              'placeholder:text-slate-400': !error,
              'placeholder:text-red-300': !!error,
            },
          )}
          onInput={handleInput}
        />
        {hasMaxLength && (
          <div
            className={classNames(
              'leading-1 w-10 rounded-md py-1 text-xs',
              'text-center font-medium',
              'transition-all duration-500',
              {
                'opacity-0':
                  showMaxLengthPercentage !== undefined &&
                  currentLength < maxLength * showMaxLengthPercentage,
                'opacity-100':
                  showMaxLengthPercentage !== undefined &&
                  currentLength >= maxLength * showMaxLengthPercentage,
                'bg-slate-500  text-slate-200':
                  !error && currentLength < maxLength * 0.5,
                'bg-red-200 text-slate-900':
                  !error &&
                  currentLength >= maxLength * 0.5 &&
                  currentLength < maxLength * 0.75,
                'bg-red-600 text-red-200':
                  Boolean(error) || currentLength >= maxLength * 0.75,
              },
            )}
          >
            {maxLength - currentLength}
          </div>
        )}
      </div>
    </div>
  );
}

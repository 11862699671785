import {
  createContext,
  useContext,
  useMemo,
  useState,
  PropsWithChildren,
  useCallback,
} from 'react';
import { PopUp, Props as PopUpProps } from '../components/PopUp';

export interface PopUpContextState extends PopUpProps {}

type ShowPopup = (params: PopUpContextState) => void;

interface PopupContext {
  showPopUp: ShowPopup;
}

const PopUpContext = createContext<PopupContext | null>(null);

function PopupProvider({ children }: PropsWithChildren<{}>) {
  const [state, setState] = useState<PopUpContextState | null>(null);

  const showPopUp = useCallback<ShowPopup>(
    (params: PopUpContextState) => {
      if (state) {
        return;
      }

      setState(params);
    },
    [state, setState],
  );

  const handleOk = useCallback<() => void>(() => {
    state?.onOk?.();

    setState(null);
  }, [state]);

  const handleCancel = useCallback<() => void>(() => {
    state?.onCancel?.();

    setState(null);
  }, [state]);

  const value = useMemo(() => {
    return {
      showPopUp,
    };
  }, [showPopUp]);

  return (
    <PopUpContext.Provider value={value}>
      {children}
      {state && <PopUp {...state} onOk={handleOk} onCancel={handleCancel} />}
    </PopUpContext.Provider>
  );
}

function usePopup() {
  const context = useContext(PopUpContext);

  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }

  return context;
}

export { usePopup, PopupProvider };

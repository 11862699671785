import { ArrowCircleLeft, ArrowCircleRight } from 'phosphor-react';
import { classNames } from '../utils/classNames';

interface Props {
  isLeft?: boolean;
}

function LandingFeedScrollButton({ isLeft }: Props) {
  return (
    <div className="h-full w-full text-white">
      {isLeft ? (
        <ArrowCircleLeft className="h-full w-full pl-4" weight="light" />
      ) : (
        <ArrowCircleRight className="h-full w-full pr-4" weight="light" />
      )}
      <div
        className={classNames('absolute left-0 top-0 h-full w-full', {
          'bg-gradient-to-l from-white to-transparent opacity-50': Boolean(
            !isLeft,
          ),
          'bg-gradient-to-r from-white to-transparent opacity-50':
            Boolean(isLeft),
        })}
      ></div>
    </div>
  );
}

export function LandingFeedScrollButtonLeft() {
  return <LandingFeedScrollButton isLeft />;
}

export function LandingFeedScrollButtonRight() {
  return <LandingFeedScrollButton />;
}

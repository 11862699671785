import { URL_LOCAL, URL_PROD } from '../settings';

export function getBaseUrl() {
  return process.env.NODE_ENV === 'production' ? URL_PROD : `${URL_LOCAL}/app`;
}

export function getApiUrl(path: string, params?: { [key: string]: string }) {
  let queryParams = '';

  if (params) {
    queryParams = Object.entries(params).reduce<string>(
      (currentValue, [key, value]) => {
        if (currentValue === '') {
          return `?${key}=${value}`;
        }

        return `${currentValue}&${key}=${value}`;
      },
      '',
    );
  }

  return getBaseUrl() + path + queryParams;
}

import {
  FormEventHandler,
  InputHTMLAttributes as TextAreaHTMLAttributes,
  useCallback,
  useState,
} from 'react';
import { classNames } from '../utils/classNames';

interface Props extends TextAreaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
}

function getLength(
  value: string | number | readonly string[] | undefined,
): number {
  return value === undefined || typeof value === 'number' ? 0 : value.length;
}

function TextAreaInput(props: Props) {
  const { value, error, maxLength, onInput, ...restProps } = props;
  const hasMaxLength = maxLength !== undefined;
  const [currentLength, setCurrentLength] = useState<number>(getLength(value));

  const handleInput = useCallback<FormEventHandler<HTMLTextAreaElement>>(
    (ev) => {
      if (hasMaxLength) {
        setCurrentLength(getLength(ev.currentTarget.value));
      }

      if (onInput) {
        onInput(ev);
      }
    },
    [onInput, hasMaxLength],
  );

  return (
    <div
      className={classNames(
        'relative flex h-full w-full items-center rounded-md p-2 text-sm shadow-inner focus-visible:bg-slate-600',
        {
          'bg-slate-100 shadow-slate-200': !error,
          'bg-red-700 shadow-red-800': Boolean(error),
        },
      )}
    >
      <textarea
        {...restProps}
        placeholder={error || props.placeholder}
        className={classNames(
          'h-full w-full rounded-md p-0 pr-1',
          'resize-none bg-transparent',
          'border-transparent focus:border-transparent focus:ring-0',
          {
            'placeholder:text-slate-400': !error,
            'placeholder:text-red-300': !!error,
          },
        )}
        onInput={handleInput}
        value={value}
      />
      {hasMaxLength && (
        <div
          className={classNames(
            'leading-1 absolute bottom-2 right-2 w-10 rounded-md py-1 text-xs',
            'text-center font-medium',
            'transition-all duration-300',
            {
              'opacity-0': !error && currentLength < maxLength * 0.5,
              'bg-red-200 text-slate-900 opacity-100':
                !error &&
                currentLength >= maxLength * 0.5 &&
                currentLength < maxLength * 0.75,
              'bg-red-600 text-red-200 opacity-100':
                Boolean(error) || currentLength >= maxLength * 0.75,
            },
          )}
        >
          {maxLength - currentLength}
        </div>
      )}
    </div>
  );
}

export function TextArea(props: Props) {
  const { label, name } = props;

  if (label && !name) {
    throw new Error('if using label you need to pass a name');
  }

  if (label) {
    return (
      <div className="flex w-full flex-col justify-between md:flex-row md:items-center">
        <label className="md:w-32" htmlFor={name}>
          {label}
        </label>
        <div className="flex-grow">
          <TextAreaInput {...props} />
        </div>
      </div>
    );
  }

  return <TextAreaInput {...props} />;
}

export class StatusException extends Error {
  public get status() {
    return this._status;
  }

  private _status: number;

  constructor(status: number, message?: string) {
    super(message);

    this._status = status;
  }
}

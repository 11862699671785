import { useLocation } from 'react-router-dom';
import { Button } from '../components/Button';
import { useStripeCheckout } from '../queries/useStripeCheckout';
import { useStripeProducts } from '../queries/useStripeProducts';

export function ScreenPay() {
  const { pathname } = useLocation();
  const { isLoading, error, data } = useStripeProducts();
  const {
    isLoading: isCheckingOut,
    handleCheckout,
    error: errorCheckout,
  } = useStripeCheckout(data?.prices[0].id, pathname);

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (error) {
    console.error(error);
    return <div>There was an error getting stripe products</div>;
  }

  if (errorCheckout) {
    console.error(errorCheckout);
    return <div>There was an error checking out</div>;
  }

  return (
    <div className="prose prose-base m-4 mt-8">
      <h1>Looks like you're new here.</h1>
      <p>
        To access all the content of Creator Camp you have be a monthly paid
        subscriber.
      </p>
      <p>To setup your payments click below</p>
      <Button isLoading={isCheckingOut} onClick={handleCheckout}>
        Setup Payments
      </Button>
    </div>
  );
}

import { ReactNode } from 'react';
import { Button } from './Button';

export interface Props {
  message: ReactNode;
  onOk(): void;
  onCancel(): void;
  copyOk?: string;
  copyCancel?: string;
}

export function ConfirmPopup({
  message,
  onOk,
  onCancel,
  copyOk = 'Yes',
  copyCancel = 'No',
}: Props) {
  return (
    <div className="w-11/12 rounded-lg bg-slate-50 p-6 shadow-lg md:w-1/2">
      <div>{message}</div>
      <div className="flex flex-row justify-end gap-4 pt-6">
        <Button kind="positive" onClick={onOk}>
          {copyOk}
        </Button>
        <Button kind="negative" onClick={onCancel}>
          {copyCancel}
        </Button>
      </div>
    </div>
  );
}

import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { getApiUrl } from '../utils/getUrl';
import { useApiHeaders } from './useApiHeaders';
import { PublicUser } from '../@types/PublicUser';

export function queryKey(uid: string | undefined) {
  return ['profile', { uid }];
}

export function useGetPublicProfile(uid: string | undefined) {
  const headers = useApiHeaders();

  const { isLoading, isIdle, data, error } = useQuery<PublicUser, AxiosError>(
    queryKey(uid),
    async () => {
      const { data } = await axios.get<PublicUser>(
        getApiUrl(`/users/profile/${uid}`),
        {
          headers,
        },
      );

      return data;
    },
    {
      enabled: Boolean(uid) && Boolean(headers),
    },
  );

  return {
    isLoading: isLoading || isIdle,
    data,
    error,
  };
}

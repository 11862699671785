import { useQuery } from 'react-query';
import { getProducts } from '@stripe/firestore-stripe-payments';
import { stripePayments } from '../firebase';

export function useStripeProducts() {
  const { isLoading, error, data } = useQuery('stripe_products', async () => {
    const products = await getProducts(stripePayments, {
      activeOnly: true,
      includePrices: true,
    });

    if (products.length !== 1) {
      throw new Error('Stripe products returned unexpected number of products');
    }

    return products[0];
  });

  return { isLoading, error, data };
}

import { Routes, Route } from 'react-router-dom';
import {
  ROUTE_EDIT_VIDEO,
  ROUTE_VIDEOS,
  ROUTE_VIDEO_CREATE,
  ROUTE_VIDEO,
  ROUTE_SUB_COMPLETE,
  ROUTE_SUB_CANCELLED,
  ROUTE_PROFILE,
  ROUTE_EDIT_PROFILE,
} from './routes';
import { ScreenCreateVideo } from './screens/ScreenCreateVideo';
import { Screen404 } from './screens/Screen404';
import { ScreenVideo } from './screens/ScreenVideo';
import { ScreenLoggingIn } from './screens/ScreenLoggingIn';
import { ScreenVideos } from './screens/ScreenVideos';
import { ScreenLanding } from './screens/ScreenLanding';
import { AccessLevel } from './@types/access-level';
import { ScreenPublicVideo } from './screens/ScreenPublicVideo';
import { ScreenSubscriptionCreated } from './screens/ScreenSubscriptionCreated';
import { ScreenSubscriptionCancelled } from './screens/ScreenSubscriptionCancelled';
import { ScreenEditProfile } from './screens/ScreenEditProfile';
import { ScreenPublicProfile } from './screens/ScreenPublicProfile';

export function Router() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ScreenLoggingIn minAccessLevel={AccessLevel.SubscriberPaid}>
            <ScreenLanding />
          </ScreenLoggingIn>
        }
      />
      <Route
        path={ROUTE_PROFILE}
        element={
          <ScreenLoggingIn>
            <ScreenPublicProfile />
          </ScreenLoggingIn>
        }
      />
      <Route
        path={ROUTE_EDIT_PROFILE}
        element={
          <ScreenLoggingIn>
            <ScreenEditProfile />
          </ScreenLoggingIn>
        }
      />
      <Route
        path={ROUTE_VIDEO_CREATE}
        element={
          <ScreenLoggingIn minAccessLevel={AccessLevel.Creator}>
            <ScreenCreateVideo />
          </ScreenLoggingIn>
        }
      />
      <Route
        path={ROUTE_VIDEO}
        element={
          <ScreenLoggingIn minAccessLevel={AccessLevel.SubscriberPaid}>
            <ScreenPublicVideo />
          </ScreenLoggingIn>
        }
      />
      <Route
        path={ROUTE_EDIT_VIDEO}
        element={
          <ScreenLoggingIn minAccessLevel={AccessLevel.Creator}>
            <ScreenVideo />
          </ScreenLoggingIn>
        }
      />
      <Route
        path={ROUTE_VIDEOS}
        element={
          <ScreenLoggingIn minAccessLevel={AccessLevel.Creator}>
            <ScreenVideos />
          </ScreenLoggingIn>
        }
      />
      <Route
        path={ROUTE_SUB_COMPLETE}
        element={
          <ScreenLoggingIn>
            <ScreenSubscriptionCreated />
          </ScreenLoggingIn>
        }
      />
      <Route
        path={ROUTE_SUB_CANCELLED}
        element={
          <ScreenLoggingIn>
            <ScreenSubscriptionCancelled />
          </ScreenLoggingIn>
        }
      />
      <Route path="*" element={<Screen404 />} />
    </Routes>
  );
}

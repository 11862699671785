import {
  onCurrentUserSubscriptionUpdate,
  getCurrentUserSubscriptions,
  Subscription,
} from '@stripe/firestore-stripe-payments';
import { useQuery } from 'react-query';
import { useUser } from '../contexts/UserContext';
import { stripePayments } from '../firebase';

async function getSubscriptions() {
  const subscriptions = await getCurrentUserSubscriptions(stripePayments);

  if (subscriptions.length > 0) {
    return subscriptions;
  }

  return new Promise<Subscription[]>((resolve, reject) => {
    try {
      onCurrentUserSubscriptionUpdate(stripePayments, (snapshot) => {
        const duplicateLookup: { [key: string]: boolean | undefined } = {};
        const subscriptions = snapshot.changes
          .filter(
            (change) => change.type === 'added' || change.type === 'modified',
          )
          .filter((change) => {
            if (!duplicateLookup[change.subscription.id]) {
              duplicateLookup[change.subscription.id] = true;

              return true;
            }

            return false;
          })
          .map((change) => change.subscription);

        resolve(subscriptions);
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function useStripeGetSubscription() {
  const { refereshClaims } = useUser();
  const {
    isLoading,
    isIdle,
    data: isSubscribed,
    error,
  } = useQuery<boolean>(
    'user-subscriptions',
    async () => {
      if (!refereshClaims) {
        throw new Error('refereshClaims was undefined');
      }

      const subscriptions = await getSubscriptions();

      if (subscriptions.length === 0) {
        return false;
      }

      const isSubscribed = subscriptions.reduce((isSubscribed, sub) => {
        if (isSubscribed) return true;

        return sub.status === 'active';
      }, false);

      if (!isSubscribed) {
        return false;
      }

      await refereshClaims();

      return true;
    },
    {
      enabled: Boolean(refereshClaims),
    },
  );

  return {
    isLoading: isLoading || isIdle,
    isSubscribed,
    error,
  };
}

import { PropsWithChildren } from 'react';
import { ChannelDetails as ChannelDetailsType } from '../../@types/ChannelDetails';
import { Button } from '../../components/Button';
import { useUser } from '../../contexts/UserContext';
import { useGetChannelDetails } from '../../queries/useGetChannelDetails';
import { linkAnotherAccount } from './linkAnotherAccount';

function formatNumber(value: number) {
  if (value <= 1_000) {
    return value;
  }

  if (value >= 1_000 && value < 1_000_000) {
    return `${(value / 1_000).toFixed(1)}k`;
  }

  return `${(value / 1_000_000).toFixed(1)}mil`;
}

function AddOrSwitchButton({ children }: PropsWithChildren<{}>) {
  const { isLoading, idToken } = useUser();

  return (
    <Button
      isLoading={isLoading}
      onClick={() => {
        linkAnotherAccount(idToken!);
      }}
    >
      {children}
    </Button>
  );
}

function NoChannel() {
  return (
    <div className="flex flex-col justify-between">
      <div className="pt-4">You have no channel connected</div>
      <div className="flex justify-end pt-4 pb-4 md:mt-0">
        <AddOrSwitchButton>Connect Channel</AddOrSwitchButton>
      </div>
    </div>
  );
}

function Channel({ url, stats }: Pick<ChannelDetailsType, 'url' | 'stats'>) {
  return (
    <div className="flex flex-col justify-between">
      <div className="pt-2 text-xs font-medium md:text-base md:font-normal">
        <a
          className="overflow-hidden"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          {url}
        </a>
      </div>
      {stats && (
        <div className="pt-4">
          <ChannelStats stats={stats} />
        </div>
      )}
      <div className="flex justify-end pt-4 pb-4 md:mt-0">
        <AddOrSwitchButton>Switch Channel</AddOrSwitchButton>
      </div>
    </div>
  );
}

function ChannelStats({ stats }: Pick<ChannelDetailsType, 'stats'>) {
  if (!stats) {
    return <></>;
  }

  return (
    <div className="flex justify-between text-center">
      {stats.subscriberCount !== undefined && (
        <div>
          <div className="text-4xl">{formatNumber(stats.subscriberCount)}</div>
          <div className="text-sm">Subscribers</div>
        </div>
      )}
      {stats.viewCount !== undefined && (
        <div>
          <div className="text-4xl">{formatNumber(stats.viewCount)}</div>
          <div className="text-sm">Views</div>
        </div>
      )}
      {stats.videoCount !== undefined && (
        <div>
          <div className="text-4xl">{formatNumber(stats.videoCount)}</div>
          <div className="text-sm">Videos</div>
        </div>
      )}
    </div>
  );
}

interface Props {
  channelId?: string;
}

export function ChannelDetails({ channelId }: Props) {
  const { isLoading, data, error } = useGetChannelDetails(channelId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    <div>There was an error getting channel details</div>;
  }

  return (
    <div className="mb-8">
      <div className="pb-2 text-lg">Connected YouTube Channel</div>
      <hr />

      {data ? <Channel {...data} /> : <NoChannel />}
    </div>
  );
}

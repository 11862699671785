import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useApiHeaders } from './useApiHeaders';
import { getApiUrl } from '../utils/getUrl';
import { renameFile } from '../utils/renameFile';

interface ImpageUploadPathResponse {
  uid: string;
  uploadURL: string;
}

interface ImageUploadMarkCompleteResponse {
  uid: string;
  photoUrlSmall: string;
  photoURL: string;
  photoUrlLarge: string;
}

function renameIfDevelopmentFile(file: File): File {
  if (process.env.NODE_ENV === 'development') {
    return renameFile(file, 'profile-image-env-development');
  } else if (process.env.NODE_ENV === 'test') {
    return renameFile(file, 'profile-image-env-test');
  }

  return file;
}

export function useUpdateProfileImage(file: File | null) {
  const headers = useApiHeaders();
  const client = useQueryClient();

  const {
    isLoading,
    error,
    data: photoURL,
    mutateAsync: updateProfileImage,
  } = useMutation<string, Error>(
    async () => {
      if (!file) {
        throw new Error('cannot update without a file');
      }

      let uploadURL: string;

      // get an upload url
      try {
        const resultImageUploadPath =
          await axios.patch<ImpageUploadPathResponse>(
            getApiUrl('/users/profile/image_upload_path'),
            undefined,
            {
              headers,
            },
          );

        ({ uploadURL } = resultImageUploadPath.data);
      } catch (error) {
        throw new Error(
          'There was an error while getting an profile image upload url. Please try again later...',
        );
      }

      // upload image
      try {
        const form = new FormData();
        form.append('file', renameIfDevelopmentFile(file));

        await axios.post(uploadURL, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (error) {
        throw new Error(
          'There was an error while uploading your profile image. Please try again later...',
        );
      }

      // mark upload complete
      try {
        const resultMarkUploadComplete =
          await axios.patch<ImageUploadMarkCompleteResponse>(
            getApiUrl('/users/profile/image_mark_complete'),
            { uploadURL },
            {
              headers,
            },
          );

        return resultMarkUploadComplete.data.photoURL;
      } catch (error) {
        throw new Error(
          'There was an error while finishing your profile image upload. Please try again later...',
        );
      }
    },
    {
      onSuccess: () => {
        client.invalidateQueries('my-profile');
      },
    },
  );

  return { isLoading, error, photoURL, updateProfileImage };
}

import { AxiosRequestHeaders } from 'axios';
import { useAuthHeaders } from './useAuthHeaders';

export function useApiHeaders(): AxiosRequestHeaders | undefined {
  const authHeaders = useAuthHeaders();

  if (!authHeaders) {
    return undefined;
  }

  return {
    ...authHeaders,
    'content-type': 'application/json',
  };
}

import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { getApiUrl } from '../utils/getUrl';
import { useApiHeaders } from './useApiHeaders';
import { queryKey as queryKeyGetVideo } from './useGetVideo';
import { queryKey as queryKeyGetVideos } from './useGetVideos';

export function useRemoveVideo(id: string) {
  const headers = useApiHeaders();
  const client = useQueryClient();

  const { isLoading, error, mutateAsync } = useMutation(
    () => {
      return axios.delete(getApiUrl(`/videos/remove/${id}`), {
        headers,
      });
    },
    {
      onSuccess: () => {
        client.removeQueries(queryKeyGetVideo(id, false));
        client.invalidateQueries(queryKeyGetVideos());
      },
    },
  );

  return { isLoading, error, remove: mutateAsync };
}

import { useEffect } from 'react';
import { useToast } from './ToastContainer';

export function UrlErrorToast() {
  const { addToast } = useToast();

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const message = search.get('error');

    if (message) {
      addToast({
        type: 'error',
        message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

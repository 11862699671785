import { useState, createContext, useContext, PropsWithChildren } from 'react';
import { Toast, ToastType } from './Toast';

interface ToastItem {
  type: ToastType;
  message: string;
}

interface ToastItemWithKey extends ToastItem {
  key: string;
}

interface ToastContextValue {
  toastItems: ToastItemWithKey[];
  addToast(newToast: ToastItem): void;
}

const ToastContext = createContext<ToastContextValue | null>(null);

export function useToast() {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error(
      'before you can useToast you need to add the ToastContainer',
    );
  }

  const { addToast } = context;

  return {
    addToast,
  };
}

let numToastsCreated: number = 0;

export function ToastContainer({ children }: PropsWithChildren<{}>) {
  const [toastItems, setToastItems] = useState<ToastItemWithKey[]>([]);

  function addToast(newToast: ToastItem) {
    setToastItems((previousState) => {
      return [
        ...previousState,
        { ...newToast, key: `Toast-${numToastsCreated++}` },
      ];
    });
  }

  function removeToast(index: number) {
    const newToastItems = [...toastItems];
    newToastItems.splice(index, 1);
    setToastItems(newToastItems);
  }

  return (
    <ToastContext.Provider value={{ toastItems, addToast }}>
      <div className="fixed left-1/2 top-2 z-50 flex -translate-x-1/2 flex-col justify-center gap-2">
        {toastItems.map(({ key, message, type }, i) => {
          return (
            <Toast
              key={key}
              message={message}
              type={type}
              onClose={() => {
                removeToast(i);
              }}
            />
          );
        })}
      </div>
      {children}
    </ToastContext.Provider>
  );
}

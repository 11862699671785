import { useRef, useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { classNames } from '../utils/classNames';

interface Props {
  name?: string;
  label?: string;
  value?: boolean;
  disabled?: boolean;
  accessibility: string;
  bgColorOn?: string;
  bgColorOff?: string;
  colorOn?: string;
  colorOff?: string;
  size?: 'small' | 'medium' | 'large';
  onChange?(enabled: boolean): void;
  onValue?: string;
  offValue?: string;
}

function getValue(
  enabled: boolean,
  onValue: string = 'true',
  offValue: string = 'false',
): string {
  return enabled ? onValue : offValue;
}

export function Toggle({
  name,
  label,
  value,
  disabled,
  accessibility,
  colorOn,
  colorOff,
  bgColorOn,
  bgColorOff,
  size = 'medium',
  onChange,
  onValue,
  offValue,
}: Props) {
  const [enabled, setEnabled] = useState<boolean>(Boolean(value));
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const hiddenRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isDirty) {
      return;
    }

    if (onChange) {
      onChange(enabled);
    }

    setIsDirty(false);
  }, [enabled, isDirty, onChange]);

  return (
    <div className="flex flex-row">
      <input
        type="hidden"
        ref={hiddenRef}
        name={name}
        value={getValue(enabled, onValue, offValue)}
      />
      {label && <label className="w-32">{label}</label>}
      <div>
        <Switch
          disabled={disabled}
          checked={enabled}
          onChange={(value) => {
            setEnabled(value);

            setIsDirty(true);
          }}
          className={classNames(
            'relative inline-flex items-center rounded-full transition-all duration-100',
            {
              'w-6': size === 'small',
              'h-3': size === 'small',
              'w-12': size === 'medium',
              'h-6': size === 'medium',
              'w-24': size === 'large',
              'h-12': size === 'large',
              [bgColorOn || 'bg-slate-600']: enabled,
              [bgColorOff || 'bg-slate-300']: !enabled,
            },
          )}
        >
          <span className="sr-only">{accessibility}</span>
          <span
            className={classNames(
              'inline-block h-4 w-4 transform rounded-full transition-all duration-100',
              {
                'w-1': size === 'small',
                'h-1': size === 'small',
                'w-4': size === 'medium',
                'h-4': size === 'medium',
                'w-10': size === 'large',
                'h-10': size === 'large',
                [colorOn || 'bg-slate-300']: enabled,
                [colorOff || 'bg-white']: !enabled,
                'translate-x-1': !enabled,
                'translate-x-4': size === 'small' && enabled,
                'translate-x-6': size === 'medium' && enabled,
                'translate-x-12': size === 'large' && enabled,
              },
            )}
          />
        </Switch>
      </div>
    </div>
  );
}

import { useMemo } from 'react';
import { LandingFeedItemContainer } from './LandingFeedItemContainer';
import { SkeletonLineTitle } from './SkeletonLine';
import { SkeletonVideoPreview } from './SkeletonVideoPreview';

const PAGE_SIZE = 4;
const MIN_ITEMS = 2;
const MAX_ITEMS = 4;
const ITEMS_DIFF = MAX_ITEMS - MIN_ITEMS;

export function SkeletonLandingFeed() {
  const numItems = useMemo(() => {
    return Math.round(Math.random() * ITEMS_DIFF + MIN_ITEMS);
  }, []);

  const items = new Array(numItems).fill(null).map((_value, i) => {
    const isFirst = i % numItems === 0;
    const isLast = i > 0 && i % (numItems - 1) === 0;
    const isMiddle = !(isFirst || isLast);

    return (
      <LandingFeedItemContainer
        key={`landingItem-${i}`}
        isFirst={isFirst}
        isMiddle={isMiddle}
        isLast={isLast}
        pageSize={PAGE_SIZE}
      >
        <SkeletonVideoPreview />
      </LandingFeedItemContainer>
    );
  });

  return (
    <div>
      <SkeletonLineTitle className="ml-4 mb-2 w-1/5" />
      <div className="flex">{items}</div>
    </div>
  );
}

import { useEffect } from 'react';
import { usePollProcessing } from '../../queries/usePollProcessing';
import { VideoStatus } from '../../@types/VideoStatus';

interface Response {
  status: VideoStatus;
}

interface Props {
  id: string;
  onComplete(video: Response): void;
  onError(error: Error): void;
}

export function ProcessVideo({ id, onComplete, onError }: Props) {
  const { isLoading, data, error } = usePollProcessing(id);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (error) {
      onError(error);
      return;
    }

    if (!data) {
      return;
    }

    onComplete(data);
  }, [isLoading, onComplete, onError, data, error]);

  return <></>;
}

import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { Video } from '../@types/video';
import { getApiUrl } from '../utils/getUrl';
import { useApiHeaders } from './useApiHeaders';
import { queryKey as queryKeyGetVideo } from './useGetVideo';
import { queryKey as queryKeyGetVideos } from './useGetVideos';

type VideoUpdate = Partial<
  Pick<Video, 'status' | 'title' | 'description' | 'tags'>
>;

export function useUpdateVideo(id: string, updateData: VideoUpdate) {
  const headers = useApiHeaders();
  const client = useQueryClient();

  const { isLoading, error, mutate } = useMutation(
    () => {
      return axios.patch(getApiUrl(`/videos/update/${id}`), updateData, {
        headers,
      });
    },
    {
      onSuccess: () => {
        client.invalidateQueries(queryKeyGetVideo(id, false));
        client.invalidateQueries(queryKeyGetVideos());
      },
    },
  );

  return { isLoading, error, update: mutate };
}

import { useState } from 'react';
import { Feed } from '../@types/Feed';
import { classNames } from '../utils/classNames';
import { VideoPreview } from './VideoPreview';
import {
  LandingFeedScrollButtonLeft as ScrollLeftButton,
  LandingFeedScrollButtonRight as ScrollRightButton,
} from './LandingFeedScrollButton';
import { useNavigate } from 'react-router-dom';
import { videoRoute } from '../routes';
import { LandingFeedItemContainer } from './LandingFeedItemContainer';
import { useInfiniteFeed } from '../queries/useInfiniteFeed';

interface FeedProps extends Feed {}

const PAGE_SIZE = 4;

export function LandingFeed({
  title: feedTitle,
  feed: initialFeed,
  next,
}: FeedProps) {
  const navigate = useNavigate();
  const [currentPage, setPage] = useState(0);
  const [isOver, setIsOver] = useState(false);
  const {
    data: feedInfinite,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteFeed({
    title: feedTitle,
    feed: initialFeed,
    next,
  });

  const feed = feedInfinite.length ? feedInfinite : initialFeed;

  const numPages = Math.ceil(feed.length / PAGE_SIZE);

  function handleScrollLeft() {
    const nextPage = currentPage - 1;

    setPage(nextPage >= 0 ? nextPage : 0);
  }

  async function handleScrollRight() {
    const nextPage = currentPage + 1;

    setPage(nextPage <= numPages - 1 ? nextPage : numPages - 1);

    if (hasNextPage && nextPage === numPages - 1) {
      await fetchNextPage();
    }
  }

  function handleOver() {
    setIsOver(true);
  }

  function handleOut() {
    setIsOver(false);
  }

  return (
    <>
      <h1 className="pl-4 pb-2 text-lg font-medium lg:text-xl">{feedTitle}</h1>
      <div className="relative" onMouseOver={handleOver} onMouseOut={handleOut}>
        <div className="w-full overflow-x-scroll lg:overflow-x-hidden">
          <div
            style={{
              transform: `translate(${window.innerWidth * -currentPage}px)`,
            }}
            className="flex transition-transform duration-300 ease-in-out"
          >
            {feed.map(
              ({ id, previewImageMedium, title: videoTitle, user }, i) => {
                const isFirst = i % PAGE_SIZE === 0;
                const isLast = i > 0 && i % (PAGE_SIZE - 1) === 0;
                const isMiddle = !(isFirst || isLast);

                return (
                  <LandingFeedItemContainer
                    key={`${feedTitle}-${i}`}
                    isFirst={isFirst}
                    isLast={isLast}
                    isMiddle={isMiddle}
                    pageSize={PAGE_SIZE}
                  >
                    <VideoPreview
                      profileImageUrl={user?.photoURL}
                      uid={user.uid}
                      displayName={
                        user && user.displayName ? user.displayName : 'Unknown'
                      }
                      previewImage={previewImageMedium}
                      title={videoTitle}
                      onClick={() => {
                        navigate(videoRoute(id));
                      }}
                    />
                  </LandingFeedItemContainer>
                );
              },
            )}
          </div>
        </div>
        <div
          className={classNames(
            'absolute left-0 top-0 hidden h-full w-20 cursor-pointer transition-opacity duration-300',
            {
              'md:block': numPages !== 1 && currentPage !== 0,
              'opacity-100': isOver,
              'opacity-0': !isOver,
            },
          )}
          onClick={handleScrollLeft}
        >
          <ScrollLeftButton />
        </div>
        <div
          className={classNames(
            'absolute right-0 top-0 hidden h-full w-20 cursor-pointer transition-opacity duration-300',
            {
              'md:block': numPages !== 1 && currentPage !== numPages - 1,
              'opacity-100': isOver,
              'opacity-0': !isOver,
            },
          )}
          onClick={handleScrollRight}
        >
          <ScrollRightButton />
        </div>
      </div>
    </>
  );
}

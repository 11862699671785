import { useParams, Navigate } from 'react-router-dom';
import {
  PublicVideoInfo,
  SkeletonPublicVideoInfo,
} from '../components/PublicVideoInfo';
import { SkeletonBase } from '../components/SkeletonBase';
import { VideoPlayer } from '../components/VideoPlayer';
import { NotFoundException } from '../exceptions/NotFoundException';
import { useGetVideo } from '../queries/useGetVideo';

type RouteParams = 'videoId';

export function ScreenPublicVideo() {
  const { videoId } = useParams<RouteParams>();
  const { isLoading, data, error } = useGetVideo(true, videoId);

  if (!videoId) {
    return <Navigate to={'/404'} />;
  }

  if (error) {
    if (error instanceof NotFoundException) {
      return <div>Video could not be found</div>;
    } else {
      return <div>There was an error getting the video</div>;
    }
  }

  return (
    <div className="flex flex-col gap-4 md:m-4 md:mt-4 md:flex-row">
      <div className="h-max overflow-hidden md:w-3/4">
        {!isLoading && data ? (
          <VideoPlayer
            playbackId={data.playbackIdPublic}
            poster={data.previewImageLarge}
          />
        ) : (
          <div className="aspect-w-16 aspect-h-9">
            <SkeletonBase className="h-full w-full" />
          </div>
        )}
      </div>
      <div className="mx-4 md:mx-0 md:w-1/4">
        <div className="aspect-w-[53] aspect-h-[90]">
          {!isLoading && data ? (
            <PublicVideoInfo
              title={data.title}
              description={data.description}
              publishDate={data.publishDate}
              uid={data.user.uid}
              displayName={data.user.displayName || ''}
              photoUrl={data.user.photoURL}
            />
          ) : (
            <SkeletonPublicVideoInfo />
          )}
        </div>
      </div>
    </div>
  );
}

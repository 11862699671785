import { useNavigate } from 'react-router';
import { Video } from '../@types/video';
import { VideoPreview } from '../components/VideoPreview';
import { useGetVideos } from '../queries/useGetVideos';
import { editVideoRoute } from '../routes';

interface VideosProps {
  videos: Video[];
}

function Videos({ videos }: VideosProps) {
  const navigate = useNavigate();

  return (
    <>
      {videos.map((video) => {
        const { id, id: key, uid, status, user, title } = video;
        const { photoURL: profileImageUrl, displayName } = user!;
        let previewImage: string | undefined = undefined;

        if (status === 'published' || status === 'unpublished') {
          previewImage = video.previewImageMedium;
        }

        return (
          <VideoPreview
            uid={uid}
            key={key}
            status={status}
            title={title}
            displayName={displayName || `user${uid}`}
            previewImage={previewImage}
            profileImageUrl={profileImageUrl}
            onClick={() => {
              navigate(editVideoRoute(id));
            }}
          />
        );
      })}
    </>
  );
}

export function ScreenVideos() {
  const { isLoading, data, error } = useGetVideos();

  return (
    <div className="px-4">
      {isLoading && <div>Loading...</div>}
      {error && (
        <div>
          Oh no there was an error gettign videos now... Try again later
        </div>
      )}
      {data && (
        <>
          <h1 className="pb-4 text-2xl">All Videos</h1>
          <div className="grid gap-4 md:grid-cols-4">
            <Videos videos={data} />
          </div>
        </>
      )}
    </div>
  );
}

import { useQuery } from 'react-query';
import { ref, uploadBytes } from 'firebase/storage';

import { getStorage } from '../firebase';

interface MutationParams {
  file: File | null;
  bucketName: string | null;
  filePath: string | null;
}

export function useUploadVideo({ file, bucketName, filePath }: MutationParams) {
  const { isLoading, isIdle, error } = useQuery<void, Error>(
    'upload_video',
    async () => {
      if (!file) {
        throw new Error('file is not defined');
      }

      if (!bucketName) {
        throw new Error('bucketName is not defined');
      }

      if (!filePath) {
        throw new Error('bucketName is not defined');
      }

      const storage = getStorage(bucketName);
      const storageRef = ref(storage, filePath);

      await uploadBytes(storageRef, file);
    },
    {
      enabled: Boolean(file && bucketName && filePath),
    },
  );

  return { isLoading, isIdle, error };
}

import { Spinner } from './Spinner';

interface Props {
  width?:
    | 'w-2'
    | 'w-4'
    | 'w-8'
    | 'w-12'
    | 'w-1/2'
    | 'w-1/4'
    | 'w-1/6'
    | 'w-1/12';
}

export function Loader({ width = 'w-1/2' }: Props) {
  return (
    <div
      className="flex h-full w-full items-center justify-center"
      data-testid="loader"
    >
      <div className={`text-white ${width}`}>
        <Spinner />
      </div>
    </div>
  );
}

type ObjectClassName = { [key: string]: boolean };
type StringClassName = string;
type ClassName = ObjectClassName | StringClassName;

export function classNames(...names: ClassName[]): string {
  return names
    .map((name) => {
      if (typeof name === 'string') {
        return name.trim();
      }

      return Object.entries(name)
        .filter(([, value]) => value)
        .map(([key]) => key)
        .join(' ');
    })
    .join(' ')
    .trim();
}

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VideoStatus } from '../../@types/VideoStatus';
import { Button } from '../../components/Button';
import { FormatDate } from '../../components/FormatDate';
import { TagInput } from '../../components/TagInput';
import { TextArea } from '../../components/TextArea';
import { TextInput } from '../../components/TextInput';
import { useToast } from '../../components/ToastContainer';
import { Toggle } from '../../components/Toggle';
import { useRemoveVideo } from '../../queries/useRemoveVideo';
import { useUpdateVideo } from '../../queries/useUpdateVideo';

interface Props {
  status: VideoStatus;
  videoId: string;
  title?: string;
  description?: string;
  publishDate: number | null;
  tags?: { [key: string]: boolean };
}

export function InfoEntry({
  status,
  videoId,
  title,
  description,
  publishDate: propsPublishDate,
  tags,
}: Props) {
  const navigate = useNavigate();
  const videoIsInPlayableState =
    status === 'published' || status === 'unpublished';
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [publishDate, setPublishDate] = useState<number | null>(
    propsPublishDate,
  );
  const [formState, setFormState] = useState<{
    title?: string;
    description?: string;
    descriptionError?: string;
    titleError?: string;
    isPublished: boolean;
    tags: string[];
  }>({
    title,
    description,
    isPublished: status === 'published',
    tags: !tags ? [] : Object.keys(tags).sort(),
  });
  const [savedState, setSavedState] = useState({
    ...formState,
    tags: [...formState.tags],
  });
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  let updateStatus: VideoStatus = status;

  if (videoIsInPlayableState) {
    updateStatus = formState.isPublished ? 'published' : 'unpublished';
  }

  const {
    isLoading: isLoadingUpdate,
    error: errorUpdate,
    update: updateVideo,
  } = useUpdateVideo(videoId, {
    status: updateStatus,
    title: formState.title,
    description: formState.description,
    tags: formState.tags.reduce(
      (tagsObject: { [key: string]: boolean }, key: string) => {
        tagsObject[key] = true;

        return tagsObject;
      },
      {},
    ),
  });
  const {
    isLoading: isLoadingRemove,
    error: errorRemoving,
    remove: removeVideo,
  } = useRemoveVideo(videoId);
  const isLoading = isLoadingUpdate || isLoadingRemove;

  const { addToast } = useToast();

  useEffect(() => {
    if (!isLoading) {
      if (errorUpdate) {
        addToast({
          message: 'Could not update video there was an error',
          type: 'error',
        });
        return;
      }

      if (errorRemoving) {
        addToast({
          message: 'Could not remove video there was an error',
          type: 'error',
        });
        return;
      }

      if (hasChanges && isUpdating) {
        setHasChanges(false);
        setIsUpdating(false);
        setPublishDate(formState.isPublished ? Date.now() : null);
        setSavedState(formState);
      }
    }
  }, [
    isLoading,
    errorUpdate,
    errorRemoving,
    hasChanges,
    setHasChanges,
    setSavedState,
    formState,
    addToast,
    isUpdating,
    setIsUpdating,
  ]);

  const handleSubmit = useCallback(() => {
    const { title, description } = formState;
    let newFormState = { ...formState };
    let hadErrors = false;

    if (!title || !title.trim()) {
      newFormState = {
        ...newFormState,
        title: '',
        titleError: 'Title is required',
      };
      hadErrors = true;
    }

    if (!description || !description.trim()) {
      newFormState = {
        ...newFormState,
        description: '',
        descriptionError: 'Description is required',
      };
      hadErrors = true;
    }

    if (hadErrors) {
      setFormState(newFormState);

      return;
    }

    setIsUpdating(true);
    updateVideo();
  }, [setIsUpdating, updateVideo, formState, setFormState]);

  const handleRemove = useCallback(async () => {
    try {
      await removeVideo();
      navigate('/');
    } catch (_error) {}
  }, [removeVideo, navigate]);

  return (
    <div className="flex h-full flex-col">
      <div>
        <TextInput
          value={formState.title || ''}
          error={formState.titleError}
          disabled={isLoadingUpdate}
          placeholder="Title"
          maxLength={100}
          onChange={() => {
            if (!formState.titleError) {
              return;
            }

            setFormState({
              ...formState,
              titleError: undefined,
            });
          }}
          onInput={(ev) => {
            setFormState({
              ...formState,
              title: ev.currentTarget.value,
            });
            setHasChanges(savedState.title !== ev.currentTarget.value);
          }}
        />
      </div>
      <div className="mt-4 max-h-80 flex-grow">
        <TextArea
          value={formState.description || ''}
          error={formState.descriptionError}
          disabled={isLoadingUpdate}
          placeholder="Description"
          maxLength={5000}
          onChange={() => {
            if (!formState.descriptionError) {
              return;
            }

            setFormState({
              ...formState,
              descriptionError: undefined,
            });
          }}
          onInput={(ev) => {
            setFormState({
              ...formState,
              description: ev.currentTarget.value,
            });
            setHasChanges(savedState.description !== ev.currentTarget.value);
          }}
        />
      </div>
      <div className="mt-4">
        <TagInput
          disabled={isLoadingUpdate}
          tags={formState.tags}
          onTags={(newTags) => {
            setFormState({
              ...formState,
              tags: [...newTags],
            });
            setHasChanges(
              savedState.tags.sort().join(' ') !== newTags.sort().join(' '),
            );
          }}
        />
      </div>
      {videoIsInPlayableState && (
        <div className="mt-6 flex w-full gap-2">
          Publish
          <div>
            <Toggle
              disabled={isLoadingUpdate}
              value={formState.isPublished}
              accessibility="Publish"
              bgColorOff="bg-slate-200"
              colorOff="bg-slate-50"
              bgColorOn="bg-slate-500"
              colorOn="bg-slate-200"
              onChange={(value) => {
                setFormState({
                  ...formState,
                  isPublished: value,
                });
                setHasChanges(value !== savedState.isPublished);
              }}
            />
          </div>
          <div className="mt-1 flex w-full text-xs text-slate-400">
            {publishDate && <FormatDate date={publishDate} />}
          </div>
        </div>
      )}

      <div className="mt-6 flex w-full justify-between">
        <Button isLoading={isLoading} onClick={handleRemove}>
          Remove Video
        </Button>

        <Button
          isLoading={isLoading}
          disabled={!hasChanges}
          onClick={handleSubmit}
        >
          Save Video
        </Button>
      </div>
    </div>
  );
}

import {
  createCheckoutSession,
  StripePaymentsError,
} from '@stripe/firestore-stripe-payments';
import { useCallback, useState } from 'react';
import { stripePayments } from '../firebase';
import { ROUTE_SUB_CANCELLED, ROUTE_SUB_COMPLETE } from '../routes';

export function useStripeCheckout(
  id: string | undefined,
  redirectRoute: string | null = null,
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<StripePaymentsError | null>(null);
  const handleCheckout = useCallback(async () => {
    if (!id) {
      return;
    }

    setIsLoading(true);

    try {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      let success_url = `${baseUrl}/${ROUTE_SUB_COMPLETE}`;

      if (redirectRoute && redirectRoute !== '/') {
        success_url = `${baseUrl}/${ROUTE_SUB_COMPLETE}?route=${redirectRoute}`;
      }

      const stripeSessionPayload = {
        price: id,
        success_url,
        cancel_url: `${baseUrl}/${ROUTE_SUB_CANCELLED}`,
      };

      console.log('Checking out with', stripeSessionPayload);

      const session = await createCheckoutSession(
        stripePayments,
        stripeSessionPayload,
      );

      setIsLoading(false);
      window.location.assign(session.url);
    } catch (error) {
      setError(error as StripePaymentsError);
      setIsLoading(false);
    }
  }, [id, redirectRoute]);

  return { isLoading, handleCheckout: id ? handleCheckout : undefined, error };
}

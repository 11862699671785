import { cloneElement, useMemo } from 'react';
import { SkeletonLine } from './SkeletonLine';

const SKELETON_LINES = [
  <SkeletonLine className="w-full" />,
  <SkeletonLine key="6/12" className="mt-1 mb-1 w-6/12" />,
  <SkeletonLine key="7/12" className="mt-1 mb-1 w-7/12" />,
  <SkeletonLine key="8/12" className="mt-1 mb-1 w-8/12" />,
  <SkeletonLine key="9/12" className="mt-1 mb-1 w-9/12" />,
  <SkeletonLine key="10/12" className="mt-1 mb-1 w-10/12" />,
  <SkeletonLine key="11/12" className="mt-1 mb-1 w-11/12" />,
];
const SKELETON_LAST_INDEX = SKELETON_LINES.length - 1;

interface SkeletonParagrahProps {
  numLines?: number;
}

export function SkeletonParagraph({ numLines = 5 }: SkeletonParagrahProps) {
  const lines = useMemo(() => {
    return new Array(numLines).fill(null).map((_value, index) => {
      const randIndex = Math.round(Math.random() * SKELETON_LAST_INDEX);

      return cloneElement(SKELETON_LINES[randIndex], { key: `line-${index}` });
    });
  }, [numLines]);

  return <div className="w-full">{lines}</div>;
}

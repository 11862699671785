import { ReactNode } from 'react';
import { ConfirmPopup } from './ConfirmPopUp';
import { Props as ConfirmProps } from './ConfirmPopUp';

type PopupTypes = 'confirm';

interface BaseProps {
  type: PopupTypes;
}

interface PopUpConfirmProps extends ConfirmProps, BaseProps {
  type: 'confirm';
}

export type Props = PopUpConfirmProps;

export function PopUp({ type, ...props }: Props) {
  let popUp: ReactNode;

  switch (type) {
    case 'confirm':
      popUp = <ConfirmPopup {...props} />;
      break;
    default:
      throw new Error(`Uknown popup type ${type}`);
  }

  return (
    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-slate-300 bg-opacity-40">
      {popUp}
    </div>
  );
}

import {
  VideoStatusPlayable,
  VideoStatusUnPlayable,
} from '../@types/VideoStatus';
import { Spinner } from './Spinner';
import { Skull } from 'phosphor-react';
import { VideoPlayer } from './VideoPlayer';

interface PropsNotPlayable {
  status: VideoStatusUnPlayable;
  playbackId?: string;
  poster?: string;
}

interface PropsPlayable {
  status: VideoStatusPlayable;
  playbackId: string;
  poster: string;
}

type Props = PropsPlayable | PropsNotPlayable;

function VideoIcon({ status }: Pick<Props, 'status'>) {
  switch (status) {
    case 'created':
    case 'processing':
      return (
        <div className="h-8 w-8">
          <Spinner />
        </div>
      );

    case 'processing_error':
      return <Skull className="h-8 w-8" />;

    case 'unpublished':
    case 'published':
      return <></>;
  }
}

function VideoCopy({ status }: Pick<Props, 'status'>) {
  switch (status) {
    case 'created':
      return <>Video is created and waiting for upload</>;

    case 'processing':
      return <>Please wait... Video is processing</>;

    case 'processing_error':
      return <>Oh no... There was an error processing this video</>;

    case 'unpublished':
    case 'published':
      return <></>;

    default:
      throw new Error(`unhandled status ${status}`);
  }
}

export function Video({ status, playbackId, poster }: Props) {
  const hasVideo =
    playbackId && (status === 'published' || status === 'unpublished');

  if (hasVideo) {
    if (!playbackId) throw new Error('Status is playable but no playbackId');
    if (!poster) throw new Error('Status is playable but no poster');
  }

  return (
    <div>
      {!hasVideo && <VideoIcon status={status} />}
      {!hasVideo && <VideoCopy status={status} />}
      {playbackId && <VideoPlayer playbackId={playbackId!} poster={poster!} />}
    </div>
  );
}

import { classNames } from '../utils/classNames';
import { SkeletonBase } from './SkeletonBase';

function SkeletonLabel() {
  const rand = Math.random() * 3;

  return (
    <SkeletonBase
      className={classNames('mb-1 h-5 md:mb-0', {
        'w-8/12': rand < 1,
        'w-9/12': rand >= 1 && rand < 2,
        'w-full:': rand >= 2,
      })}
    />
  );
}

function SkeletonInput() {
  return <SkeletonBase className="h-9 w-full grow" />;
}

function SkeletonRadio() {
  return <SkeletonBase className="h-9 w-24" />;
}

export function SkeletonTextInput() {
  return (
    <div className="flex w-full flex-col justify-between md:flex-row md:items-center md:gap-2">
      <div className="w-60">
        <SkeletonLabel />
      </div>
      <SkeletonInput />
    </div>
  );
}

export function SkeletonTextArea() {
  return (
    <div className="flex w-full flex-col justify-between md:flex-row md:items-center md:gap-2">
      <div className="w-60">
        <SkeletonLabel />
      </div>
      <SkeletonBase className="h-20 w-full grow" />
    </div>
  );
}

export function SkeletonRadioInput() {
  return (
    <div className="justify-betwee flex w-full flex-col md:flex-row md:items-center md:gap-2">
      <div className="w-60">
        <SkeletonLabel />
      </div>
      <div className="h-9 w-full grow">
        <SkeletonRadio />
      </div>
    </div>
  );
}

export function SkeletonButton() {
  return <SkeletonBase className="h-12 w-28" />;
}

import { PropsWithChildren } from 'react';
import { classNames } from '../utils/classNames';

interface Props {
  isFirst?: boolean;
  isMiddle?: boolean;
  isLast?: boolean;
  pageSize: number;
}

export function LandingFeedItemContainer({
  isFirst = false,
  isMiddle = false,
  isLast = false,
  pageSize,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div
      className={classNames(
        `w-3/4 md:w-1/${pageSize} inline-block flex-shrink-0 flex-grow-0`,
        {
          'pl-4 pr-2': isFirst,
          'px-2': isMiddle,
          'pl-2 pr-4': isLast,
        },
      )}
    >
      {children}
    </div>
  );
}

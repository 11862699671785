import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from './components/ToastContainer';
import { TobBar } from './components/TobBar';
import { PopupProvider } from './contexts/PopUpContext';
import { UserProvider } from './contexts/UserContext';
import { Router } from './Router';

const client = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={client}>
      <ToastContainer>
        <PopupProvider>
          <UserProvider>
            <TobBar />
            <Router />
          </UserProvider>
        </PopupProvider>
      </ToastContainer>
    </QueryClientProvider>
  );
}

export default App;

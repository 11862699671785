import { useQuery } from 'react-query';
import axios from 'axios';
import { getApiUrl } from '../utils/getUrl';
import { VideoStatus } from '../@types/VideoStatus';
import { useApiHeaders } from './useApiHeaders';

interface ProcessVideoResponse {
  id: string;
  status: VideoStatus;
}

function isComplete(status: VideoStatus | undefined): Boolean {
  return Boolean(status === 'unpublished' || status === 'processing_error');
}

export function usePollProcessing(videoId: string) {
  const headers = useApiHeaders();
  const { isLoading, data, error } = useQuery<ProcessVideoResponse, Error>(
    'poll-video',
    async () => {
      if (!videoId) {
        throw new Error('id is undefined');
      }

      const { data } = await axios.get<ProcessVideoResponse>(
        getApiUrl(`/videos/${videoId}`),
        {
          headers,
        },
      );

      return data;
    },
    {
      enabled: headers !== undefined,
      refetchInterval: (data) => {
        return isComplete(data?.status) ? false : 1000;
      },
    },
  );

  if (error) {
    return { isLoading, data, error };
  }

  if (!headers || isLoading || !isComplete(data?.status)) {
    return { isLoading: true, data: undefined, error: null };
  }

  return { isLoading, data, error };
}

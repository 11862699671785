import { useState } from 'react';
import { classNames } from '../utils/classNames';
import { Loader } from './Loader';

interface Props {
  src: string;
  alt: string;
  errorBgColor?: string;
  objectContain?: 'object-contain' | 'object-cover';
  loaderWidth?:
    | 'w-2'
    | 'w-4'
    | 'w-8'
    | 'w-12'
    | 'w-1/2'
    | 'w-1/4'
    | 'w-1/6'
    | 'w-1/12';
  onLoad?: () => void;
  onError?: () => void;
}

export function Image({
  src,
  alt,
  loaderWidth,
  errorBgColor = 'bg-slate-300',
  onLoad,
  onError,
  objectContain = 'object-contain',
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  return (
    <div className="h-full w-full">
      {isLoading && <Loader width={loaderWidth} />}
      {!hasError && (
        <img
          className={classNames('h-full w-full', objectContain, {
            invisible: isLoading,
          })}
          src={src}
          alt={alt}
          onLoad={() => {
            setIsLoading(false);

            if (onLoad) {
              onLoad();
            }
          }}
          onError={() => {
            setIsLoading(false);
            setHasError(true);

            if (onError) {
              onError();
            }
          }}
        />
      )}
      {hasError && <div className={errorBgColor}></div>}
    </div>
  );
}

import { PropsWithChildren } from 'react';
import { classNames } from '../utils/classNames';
import { Spinner } from './Spinner';

interface Props {
  kind?: 'positive' | 'negative';
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export function Button({
  kind,
  disabled,
  children,
  isLoading,
  onClick,
}: PropsWithChildren<Props>) {
  const isDisabled = Boolean(disabled || isLoading);

  return (
    <button
      className={classNames(
        'relative rounded-md p-3 font-medium tracking-tight shadow-md shadow-slate-200 transition duration-200 ease-out',
        {
          'hover:scale-105 hover:shadow-xl': !isDisabled,
          'cursor-default opacity-30': isDisabled,
          'bg-slate-500 text-slate-50 hover:shadow-slate-200':
            !kind && !isDisabled,
          'bg-gradient-to-br from-slate-300 to-slate-400 text-slate-200':
            !kind && isDisabled,
          'bg-green-500 text-green-100 hover:shadow-slate-200':
            kind === 'positive' && !isDisabled,
          'bg-gradient-to-br from-green-300 to-green-400 text-green-100':
            kind === 'positive' && isDisabled,
          'bg-rose-500 text-rose-100 hover:shadow-slate-200':
            kind === 'negative' && !isDisabled,
          'bg-gradient-to-br from-rose-300 to-rose-400 text-rose-100':
            kind === 'negative' && isDisabled,
        },
      )}
      onClick={onClick}
      disabled={isDisabled}
    >
      <span className={isLoading ? 'invisible' : ''}>{children}</span>
      <div
        className={`absolute left-0 top-0 flex h-full w-full items-center justify-center ${
          isLoading ? '' : 'invisible'
        }`}
      >
        <div className="h-6 w-6">
          <Spinner />
        </div>
      </div>
    </button>
  );
}

import { useLocation, Navigate } from 'react-router-dom';
import { useToast } from '../components/ToastContainer';
import { useStripeGetSubscription } from '../queries/useStripeUpdateAccessLevel';

export function ScreenSubscriptionCreated() {
  const location = useLocation();
  const { addToast } = useToast();
  const { isLoading, isSubscribed, error } = useStripeGetSubscription();

  const params = new URLSearchParams(location.search);
  const redirect = params.get('route') || '/';

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (error) {
    return (
      <>
        <div>There was an error. Please try again later...</div>
      </>
    );
  }

  if (!isSubscribed) {
    console.warn('User was not subscribed even after success redirect');
    addToast({
      type: 'error',
      message: 'There was an error while subscribing. Please try again',
    });
  }

  return <Navigate to={redirect} />;
}

import { useUser } from '../contexts/UserContext';

interface AuthHeaders {
  'x-auth-token': string;
}

export function useAuthHeaders(): AuthHeaders | undefined {
  const { idToken } = useUser();

  if (!idToken) {
    return undefined;
  }

  return {
    'x-auth-token': idToken,
  };
}

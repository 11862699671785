import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { getApiUrl } from '../utils/getUrl';
import { useApiHeaders } from './useApiHeaders';
import { Video } from '../@types/video';
import { PublicVideo } from '../@types/PublicVideo';
import { handleError } from './handleError';

export function queryKey(videoId: string | undefined, isPublic: boolean) {
  if (isPublic) {
    return ['public-video', { videoId }];
  }

  return ['video', { videoId }];
}

interface ReturnType<T> {
  isLoading: boolean;
  data: T | undefined;
  error: Error | null;
}

export function useGetVideo(
  isPublic: true,
  videoId: string | undefined,
): ReturnType<PublicVideo>;
export function useGetVideo(
  isPublic: false,
  videoId: string | undefined,
): ReturnType<Video>;
export function useGetVideo(
  isPublic: boolean,
  videoId: string | undefined,
): ReturnType<PublicVideo | Video> {
  const headers = useApiHeaders();

  const { isLoading, isIdle, data, error } = useQuery<
    Video | PublicVideo,
    AxiosError
  >(
    queryKey(videoId, isPublic),
    async () => {
      if (isPublic) {
        const { data } = await axios.get(
          getApiUrl(`/public-video/${videoId}`),
          {
            headers,
          },
        );

        return data;
      }

      try {
        const { data } = await axios.get(getApiUrl(`/videos/${videoId}`), {
          headers,
        });

        return data;
      } catch (error) {
        handleError(error);
      }
    },
    {
      enabled: Boolean(videoId && headers),
    },
  );

  return {
    isLoading: videoId !== undefined && (isIdle || isLoading),
    data,
    error,
  };
}

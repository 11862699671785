import { SkeletonBase } from './SkeletonBase';
import { SkeletonLine, SkeletonLineSmall } from './SkeletonLine';

export function SkeletonVideoPreview() {
  return (
    <div>
      <div className="aspect-w-16 aspect-h-9">
        <SkeletonBase className="h-full w-full" />
      </div>
      <SkeletonLine className="mt-2 w-3/4" />
      <SkeletonLineSmall className="mt-1 mb-1 w-1/4" />
    </div>
  );
}

import { X } from 'phosphor-react';
import { classNames } from '../utils/classNames';

export type ToastType = 'error' | 'info';

interface Props {
  type: ToastType;
  message: string;
  onClose: () => void;
}

export function Toast({ type, message, onClose }: Props) {
  return (
    <div className="relative text-white shadow-xl">
      <button className="absolute top-0 right-0 p-1" onClick={onClose}>
        <X
          className="transform transition duration-200 ease-out hover:rotate-90"
          weight="bold"
        />
      </button>
      <div
        className={classNames('rounded-md px-6 py-4', {
          'bg-gradient-to-b from-red-500 to-red-600': type === 'error',
          'bg-slate-500': type !== 'error',
        })}
      >
        {message}
      </div>
    </div>
  );
}

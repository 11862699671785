import { useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { useUser } from '../contexts/UserContext';
import { ProfileImage } from './ProfileImage';
import {
  ROUTE_EDIT_PROFILE,
  ROUTE_VIDEOS,
  ROUTE_VIDEO_CREATE,
} from '../routes';
import {
  CreateVideoButton,
  ProfileButton,
  SignOutButton,
  VideosButton,
} from './UserMenuButtons';
import { useGetProfile } from '../queries/useGetProfile';

function AdminUserMenu({
  handleProfile,
  handleVideos,
  handleCreateVideo,
}: {
  handleProfile(): void;
  handleVideos(): void;
  handleCreateVideo(): void;
}) {
  return (
    <>
      <Menu.Item as="div" className="p-2 focus:outline-none">
        <CreateVideoButton onClick={handleCreateVideo} />
      </Menu.Item>
      <Menu.Item as="div" className="p-2 focus:outline-none">
        <VideosButton onClick={handleVideos} />
      </Menu.Item>
      <Menu.Item as="div" className="p-2 focus:outline-none">
        <ProfileButton onClick={handleProfile} />
      </Menu.Item>
    </>
  );
}

function CreatorUserMenu({
  handleProfile,
  handleVideos,
  handleCreateVideo,
}: {
  handleProfile(): void;
  handleVideos(): void;
  handleCreateVideo(): void;
}) {
  return (
    <>
      <Menu.Item as="div" className="p-2 focus:outline-none">
        <CreateVideoButton onClick={handleCreateVideo} />
      </Menu.Item>
      <Menu.Item as="div" className="p-2 focus:outline-none">
        <VideosButton onClick={handleVideos} />
      </Menu.Item>
      <Menu.Item as="div" className="p-2 focus:outline-none">
        <ProfileButton onClick={handleProfile} />
      </Menu.Item>
    </>
  );
}

function SubscriberUserMenu({ handleProfile }: { handleProfile(): void }) {
  return (
    <Menu.Item as="div" className="p-2 focus:outline-none">
      <ProfileButton onClick={handleProfile} />
    </Menu.Item>
  );
}

export function UserMenu() {
  const { signOut } = useUser();
  const { isLoading, data: user } = useGetProfile();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="h-12 w-12 md:h-8 md:w-8">
        <ProfileImage isLoading border={false} />
      </div>
    );
  }

  if (!user) {
    throw new Error('showing UserMenu with no user');
  }

  if (!signOut) {
    throw new Error('showing UserMenu with no signOut');
  }

  async function handleSignOut() {
    if (!signOut) {
      return;
    }

    await signOut();
    navigate('/');
  }

  function handleRoute(route: string) {
    navigate(route);
  }

  const handleVideos = handleRoute.bind(undefined, ROUTE_VIDEOS);
  const handleCreateVideo = handleRoute.bind(undefined, ROUTE_VIDEO_CREATE);
  const handleProfile = handleRoute.bind(undefined, ROUTE_EDIT_PROFILE);

  return (
    <Menu as="div" className="z-50 focus:outline-none">
      <div className="flex align-middle">
        <Menu.Button className="h-11 w-11 focus:outline-none md:h-11 md:w-11">
          <ProfileImage
            profileImageUrl={user.photoURL}
            displayName={user.displayName}
            border={false}
          />
        </Menu.Button>
      </div>
      <Menu.Items className="absolute right-2 w-56 divide-y divide-gray-200 border border-slate-800 bg-white focus:outline-none">
        {user.accessLevel === 'Admin' && (
          <AdminUserMenu
            handleProfile={handleProfile}
            handleCreateVideo={handleCreateVideo}
            handleVideos={handleVideos}
          />
        )}
        {user.accessLevel === 'Creator' && (
          <CreatorUserMenu
            handleProfile={handleProfile}
            handleCreateVideo={handleCreateVideo}
            handleVideos={handleVideos}
          />
        )}
        {user.accessLevel === 'SubscriberPaid' && (
          <SubscriberUserMenu handleProfile={handleProfile} />
        )}
        {(user.accessLevel === 'Subscriber' || !user.accessLevel) && (
          <SubscriberUserMenu handleProfile={handleProfile} />
        )}
        <Menu.Item as="div" className="p-2 focus:outline-none">
          <SignOutButton onClick={handleSignOut} />
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}

import { MediaController } from 'media-chrome/dist/react';
import './VideoPlayer.css';
import { MobilePlayPauseSeek } from './VideoPlayer/MobilePlayPauseSeek';
import { BottomControls } from './VideoPlayer/BottomControls';
import HLSVideo from './HLSVideo';

interface Props {
  playbackId: string;
  poster: string;
}

export function VideoPlayer({ playbackId, poster }: Props) {
  return (
    <div className="aspect-w-16 aspect-h-9">
      <MediaController className="video-player h-full w-full">
        <HLSVideo
          slot="media"
          src={`https://stream.mux.com/${playbackId}.m3u8`}
          preload="auto"
          autoPlay={true}
          muted={false}
          poster={poster}
        />
        <BottomControls />
        <div slot="centered-chrome" className="block w-1/2 md:hidden">
          <MobilePlayPauseSeek />
        </div>
      </MediaController>
    </div>
  );
}

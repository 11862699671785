import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  getAuth,
  signInWithCustomToken,
  onAuthStateChanged,
  signOut as signOutFirebase,
} from 'firebase/auth';
import { AccessLevelName } from '../@types/access-level';
import { useToast } from '../components/ToastContainer';
import { User } from '../@types/user';
import { AllChannelSizes } from '../@types/channel-size';

export class UserError extends Error {}

export class UserErrorNoDisplayName extends UserError {
  constructor() {
    super("On no... Your account doesn't have a display name");
  }
}

type UserInfo = Required<Pick<User, 'accessLevel' | 'displayName' | 'uid'>> &
  Pick<User, 'photoURL' | 'channelSize'>;

interface UserContextState {
  isLoggedIn: boolean;
  isLoading: boolean;
  loginError?: UserError;
  state?: UserInfo;
  idToken?: string;
  refereshClaims?(): Promise<void>;
  signOut?(): void;
}

const UserContext = createContext<UserContextState | null>(null);
UserContext.displayName = 'User';

interface Claims {
  accessLevel: AccessLevelName | undefined;
  stripeRole: string | undefined;
  channelSize: AllChannelSizes | undefined;
}

function getAccessLevelFromClaims(claims: Claims): AccessLevelName {
  const { accessLevel, stripeRole } = claims;

  if (accessLevel && accessLevel !== 'Subscriber') {
    return accessLevel;
  }

  if (stripeRole) {
    return 'SubscriberPaid';
  }

  return 'Subscriber';
}

function getChannelSizeFromClaims(claims: Claims): AllChannelSizes {
  return claims.channelSize || 'unknown';
}

function getStateFromClaims(claims: Claims) {
  const accessLevel = getAccessLevelFromClaims(claims);
  const channelSize = getChannelSizeFromClaims(claims);

  return { accessLevel, channelSize };
}

function UserProvider(props: any) {
  const auth = getAuth();
  const [state, setState] = useState<UserContextState>({
    isLoading: true,
    isLoggedIn: false,
  });
  const { addToast } = useToast();

  useEffect(function handleToken() {
    async function loginWithToken() {
      const search = new URLSearchParams(window.location.search);
      const token = search.get('token');

      if (!token) {
        return;
      }

      await signInWithCustomToken(auth, token);
    }

    loginWithToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(function handleAuthStateChange() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const { uid, photoURL, displayName } = user;
          const idToken = await user.getIdToken();
          const { claims } = await user.getIdTokenResult();
          const { accessLevel, channelSize } = getStateFromClaims(
            claims as any,
          );

          if (!displayName) {
            throw new UserErrorNoDisplayName();
          }

          const refereshClaims = async () => {
            if (!user) {
              throw new Error('no user when refereshing access level');
            }

            const idToken = await user.getIdToken(true);
            const { claims } = await user.getIdTokenResult();
            const accessLevel = getAccessLevelFromClaims(claims as any);

            setState((contextState) => ({
              ...contextState,
              idToken,
              state: {
                ...(contextState.state as UserInfo),
                accessLevel,
              },
            }));
          };

          const signOut = async () => {
            await signOutFirebase(auth);
            setState({
              isLoggedIn: false,
              isLoading: false,
            });
          };

          setState({
            loginError: undefined,
            isLoggedIn: true,
            isLoading: false,
            idToken,
            refereshClaims,
            signOut,
            state: {
              uid,
              accessLevel,
              channelSize,
              displayName,
              photoURL: photoURL || undefined,
            },
          });
        } catch (loginError) {
          if (!(loginError instanceof Error)) {
            throw new Error('loginError as not an Error');
          }

          addToast({
            type: 'error',
            message: loginError.message,
          });

          setState({
            loginError,
            isLoggedIn: false,
            isLoading: false,
          });
        }

        return;
      }

      setState({
        isLoading: false,
        isLoggedIn: false,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo<UserContextState>(() => {
    return state;
  }, [state]);

  return <UserContext.Provider value={value} {...props} />;
}

function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}

export { useUser, UserProvider };

import {
  MediaControlBar,
  MediaFullscreenButton,
  MediaMuteButton,
  MediaPlayButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaTimeDisplay,
  MediaTimeRange,
  MediaVolumeRange,
} from 'media-chrome/dist/react';
import {
  Play,
  Pause,
  Rewind,
  FastForward,
  SpeakerSimpleSlash,
  SpeakerSimpleNone,
  SpeakerSimpleLow,
  SpeakerSimpleHigh,
  CornersOut,
  CornersIn,
} from 'phosphor-react';

export function BottomControls() {
  return (
    <MediaControlBar className="flex">
      <MediaPlayButton className="hidden w-11 md:block">
        <div slot="play">
          <Play className="h-full w-full" />
        </div>
        <div slot="pause">
          <Pause className="h-full w-full" />
        </div>
      </MediaPlayButton>
      <MediaSeekBackwardButton seekOffset={5} className="hidden w-11 md:block">
        <div slot="backward">
          <Rewind className="h-full w-full" />
        </div>
      </MediaSeekBackwardButton>
      <MediaSeekForwardButton seekOffset={5} className="hidden w-11 md:block">
        <div slot="forward">
          <FastForward className="h-full w-full" />
        </div>
      </MediaSeekForwardButton>
      <MediaTimeDisplay showDuration className="text-xs" />
      <MediaTimeRange />
      <MediaMuteButton className="hidden w-11 md:block">
        <div slot="off">
          <SpeakerSimpleSlash className="h-full w-full" />
        </div>
        <div slot="low">
          <SpeakerSimpleNone className="h-full w-full" />
        </div>
        <div slot="medium">
          <SpeakerSimpleLow className="h-full w-full" />
        </div>
        <div slot="high">
          <SpeakerSimpleHigh className="h-full w-full" />
        </div>
      </MediaMuteButton>
      <MediaVolumeRange className="hidden lg:block" />
      <MediaFullscreenButton className="w-11">
        <div slot="enter">
          <CornersOut className="h-full w-full" />
        </div>
        <div slot="exit">
          <CornersIn className="h-full w-full" />
        </div>
      </MediaFullscreenButton>
    </MediaControlBar>
  );
}

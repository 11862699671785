import { HTMLProps, useEffect, useRef } from 'react';
import HLS from 'hls.js';

interface Props {
  src: string;
}

export default function HLSVideo({
  src,
  ...props
}: Props & HTMLProps<HTMLVideoElement>) {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    let hls: HLS;

    if (videoRef.current) {
      const video = videoRef.current;

      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src;
      } else if (HLS.isSupported()) {
        hls = new HLS();
        hls.loadSource(src);
        hls.attachMedia(video);
      } else {
        throw new Error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef, src]);

  return <video {...props} ref={videoRef}></video>;
}

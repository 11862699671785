import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { getApiUrl } from '../utils/getUrl';
import { useApiHeaders } from './useApiHeaders';
import { User } from '../@types/user';

export function useGetProfile() {
  const headers = useApiHeaders();

  const { isLoading, isIdle, data, error } = useQuery<User, AxiosError>(
    'my-profile',
    async () => {
      const { data } = await axios.get(getApiUrl(`/users/profile`), {
        headers,
      });

      return data;
    },
    {
      enabled: Boolean(headers),
    },
  );

  return {
    isLoading: isLoading || isIdle,
    data,
    error,
  };
}

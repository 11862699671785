import ReactMarkDown from 'react-markdown';
import { userRoute } from '../routes';
import { FormatDate } from './FormatDate';
import { ProfileImage } from './ProfileImage';
import { SkeletonBase } from './SkeletonBase';
import {
  SkeletonLine,
  SkeletonLineSmall,
  SkeletonLineTitle,
} from './SkeletonLine';
import { SkeletonParagraph } from './SkeletonParagraph';

interface Props {
  uid: string;
  title: string;
  description: string;
  publishDate: number;
  displayName: string;
  photoUrl?: string;
}

export function SkeletonPublicVideoInfo() {
  return (
    <div className="flex h-full flex-col">
      <div className="flex shrink-0 grow-0 items-center gap-2">
        <SkeletonBase className="h-11 w-11" />
        <div className="flex-1">
          <SkeletonLine className="w-2/3" />
          <SkeletonLineSmall className="mt-1 w-1/4" />
        </div>
      </div>
      <SkeletonLineTitle className="mt-4 w-full" />
      {/* <div className="mt-2 h-0 min-h-full bg-green-200 overflow-auto"> */}
      <div className="mt-2 flex-1 overflow-scroll">
        <SkeletonParagraph />
      </div>
    </div>
  );
}

export function PublicVideoInfo({
  uid,
  title,
  description,
  publishDate,
  displayName,
  photoUrl,
}: Props) {
  return (
    <div className="flex h-full flex-col">
      <div className="flex shrink-0 grow-0 items-center gap-2">
        <div className="w-11">
          <a href={userRoute(uid)}>
            <ProfileImage
              displayName={displayName}
              profileImageUrl={photoUrl}
            />
          </a>
        </div>
        <div>
          <div className="text-sm font-medium">
            <a href={userRoute(uid)}>{displayName}</a>
          </div>
          <div className="text-xs font-light">
            <a href={userRoute(uid)}>
              <FormatDate date={publishDate} />
            </a>
          </div>
        </div>
      </div>
      <h1 className="mt-4 text-xl font-extrabold">{title}</h1>
      {/* <div className="mt-2 h-0 min-h-full bg-green-200 overflow-auto"> */}
      <div className="flex-1 overflow-scroll">
        <ReactMarkDown className="prose prose-sm">{description}</ReactMarkDown>
      </div>
    </div>
  );
}

import ReactMarkDown from 'react-markdown';
import { Navigate, useParams } from 'react-router-dom';
import { ProfileImage } from '../components/ProfileImage';
import { useGetPublicProfile } from '../queries/useGetPublicProfile';

type RouteParams = 'userId';

export function ScreenPublicProfile() {
  const { userId } = useParams<RouteParams>();
  const { isLoading, data } = useGetPublicProfile(userId);

  if (!userId) {
    return <Navigate to={'/404'} />;
  }

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (!data) {
    throw new Error('no data was returned');
  }

  const {
    firstName,
    lastName,
    displayName,
    photoURL,
    photoUrlLarge,
    description,
  } = data;
  const bestPhotoUrl = photoUrlLarge || photoURL;

  return (
    <div className="flex flex-col px-4 md:flex-row">
      <div className="flex w-full justify-center md:w-2/12">
        <div className="w-1/3 max-w-[10rem] md:h-full md:w-full">
          <ProfileImage
            displayName={displayName}
            profileImageUrl={bestPhotoUrl}
          />
        </div>
      </div>

      <div className="flex-grow pt-4 md:pt-0 md:pl-4">
        <div className="text-xl font-bold">
          {firstName} {lastName}
        </div>
        <div className="text-sm font-medium">@{displayName}</div>
        {description && (
          <div className="mt-4">
            <ReactMarkDown className="prose prose-sm">
              {description}
            </ReactMarkDown>
          </div>
        )}
      </div>
    </div>
  );
}

import axios from 'axios';
import { useQuery } from 'react-query';
import { LandingFeed } from '../@types/LandingFeed';
import { useUser } from '../contexts/UserContext';
import { getApiUrl } from '../utils/getUrl';

export function useGetLanding() {
  const { state: user } = useUser();
  const { isLoading, data, error } = useQuery(
    'landing',
    async () => {
      if (!user) throw new Error("can't query without a user");

      const { status, data } = await axios.get<LandingFeed>(
        getApiUrl(`/feed/landing/${user.channelSize}`),
      );

      if (status === 404) {
        throw new Error('not found');
      }

      return data;
    },
    { enabled: Boolean(user) },
  );

  return { isLoading, data, error };
}

import {
  MediaControlBar,
  MediaPlayButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
} from 'media-chrome/dist/react';
import { Play, Pause, Rewind, FastForward } from 'phosphor-react';

export function MobilePlayPauseSeek() {
  return (
    <MediaControlBar className="flex w-full">
      <MediaSeekBackwardButton seekOffset={5} className="w-1/3">
        <div slot="backward">
          <Rewind className="h-full w-full" />
        </div>
      </MediaSeekBackwardButton>
      <MediaPlayButton className="w-1/3">
        <div slot="play">
          <Play className="h-full w-full" />
        </div>
        <div slot="pause">
          <Pause className="h-full w-full" />
        </div>
      </MediaPlayButton>
      <MediaSeekForwardButton seekOffset={5} className="w-1/3">
        <div slot="forward">
          <FastForward className="h-full w-full" />
        </div>
      </MediaSeekForwardButton>
    </MediaControlBar>
  );
}

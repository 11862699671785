import { MouseEventHandler, useState } from 'react';
import { VideoStatus as VideoStatusType } from '../@types/VideoStatus';
import { userRoute } from '../routes';
import { classNames } from '../utils/classNames';
import { Image } from './Image';
import { ProfileImage } from './ProfileImage';
import { VideoStatus } from './VideoStatus';

interface Props {
  title?: string;
  previewImage?: string;
  uid: string;
  displayName: string;
  profileImageUrl?: string;
  status?: VideoStatusType;
  onClick?(): void;
}

const onClickPreventNavigationToVideo: MouseEventHandler<HTMLAnchorElement> = (
  ev,
) => {
  ev.stopPropagation();
};

type PropsVideoInfo = Pick<
  Props,
  'title' | 'profileImageUrl' | 'displayName' | 'uid'
>;

function VideoInfo({ uid, title, displayName }: PropsVideoInfo) {
  return (
    <>
      <div className="truncate text-sm font-medium md:text-sm">{title}</div>
      <div className="font-lght -mt-2 text-xs transition-opacity duration-500 group-hover:opacity-60">
        <a href={userRoute(uid)} onClick={onClickPreventNavigationToVideo}>
          by {displayName}
        </a>
      </div>
    </>
  );
}

export function VideoPreview({
  previewImage,
  title,
  profileImageUrl,
  uid,
  displayName,
  onClick,
  status,
}: Props) {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  return (
    <div
      className="group flex w-full cursor-pointer select-none flex-col gap-2"
      onClick={onClick}
    >
      <div className="relative">
        <div
          className={classNames(
            'aspect-w-16 aspect-h-9 overflow-hidden shadow-xl',
            'transition-colors duration-100',
            {
              'bg-slate-600': !isImageLoaded,
              'bg-slate-200': isImageLoaded,
            },
          )}
        >
          {previewImage && (
            <div
              className={classNames('transition-opacity duration-200', {
                'opacity-0': !isImageLoaded,
                'opacity-100': isImageLoaded,
              })}
            >
              <Image
                src={previewImage}
                alt={`Preview image: ${title}`}
                loaderWidth="w-8"
                onLoad={() => {
                  setIsImageLoaded(true);
                }}
              />
            </div>
          )}
        </div>
        <div
          className={classNames(
            'absolute left-0 top-0 h-full w-full overflow-hidden rounded-md',
            'opacity-0',
            'transition-opacity duration-500',
            'group-hover:bg-moonriseTorquise group-hover:opacity-40',
          )}
        ></div>
        <div className="absolute left-1.5 bottom-1.5 h-8 w-8">
          <a href={userRoute(uid)} onClick={onClickPreventNavigationToVideo}>
            <ProfileImage
              profileImageUrl={profileImageUrl}
              displayName={displayName}
              loaderWidth="w-1/2"
            />
          </a>
        </div>
        {status && (
          <div className="absolute right-1.5 top-1.5">
            <VideoStatus status={status} />
          </div>
        )}
      </div>
      <VideoInfo
        title={Boolean(title) ? title : '---'}
        profileImageUrl={profileImageUrl}
        uid={uid}
        displayName={displayName}
      />
    </div>
  );
}

import { InputHTMLAttributes, useCallback, useState } from 'react';
import { UserCirclePlus } from 'phosphor-react';
import { useDropzone } from 'react-dropzone';
import { classNames } from '../utils/classNames';
import { ProfileImage } from './ProfileImage';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  displayName?: string;
  profileImageUrl?: string;
  onFile?(file: File): void;
}

export function ProfileImageInput({
  displayName = '',
  profileImageUrl,
  onFile,
  disabled,
  ...inputProps
}: Props) {
  const [isImageLoaded, setImageIsLoaded] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (disabled || acceptedFiles.length === 0) {
        return;
      }

      setImageIsLoaded(false);
      setFileUrl(URL.createObjectURL(acceptedFiles[0]));
      onFile?.(acceptedFiles[0]);
    },
    [onFile, setFileUrl, disabled],
  );

  const handleImageLoaded = useCallback(() => {
    setImageIsLoaded(true);
  }, [setImageIsLoaded]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    multiple: false,
    onDrop: handleDrop,
  });

  const imageUrl = fileUrl || profileImageUrl;

  return (
    <div
      {...getRootProps()}
      className="relative h-full min-h-[2rem] w-full min-w-[2rem] max-w-md"
    >
      <div
        className={classNames('aspect-1 relative', {
          'cursor-pointer': !disabled,
          'opacity-10': Boolean(disabled),
          'opacity-50': !disabled && isDragActive,
        })}
      >
        <ProfileImage
          displayName={displayName}
          profileImageUrl={imageUrl}
          onLoad={handleImageLoaded}
        />
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-full text-slate-50">
          <div
            className={classNames(
              'h-1/2 max-h-[3rem] w-1/2 max-w-[3rem] rounded-full bg-slate-900 bg-opacity-30',
              {
                hidden: Boolean(imageUrl) && !isImageLoaded,
              },
            )}
          >
            <UserCirclePlus className="h-full w-full p-1" />
          </div>
        </div>
        <input
          {...{
            ...inputProps,
            ...getInputProps({
              onClick: (ev) => {
                if (disabled) {
                  ev.preventDefault();
                }
              },
            }),
          }}
          type="file"
          className="z-5 absolute block h-full w-full cursor-pointer opacity-0"
        />
      </div>
    </div>
  );
}

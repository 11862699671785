import { URL_LOCAL, URL_PROD } from '../../settings';

export function linkAnotherAccount(token: string) {
  const redirect = window.location.pathname.substring(1);
  const action = 'switch-youtube';

  let loginUrl: string;

  if (process.env.NODE_ENV === 'production') {
    loginUrl = `${URL_PROD}/auth?state=${JSON.stringify({
      site: 'admin',
      redirect,
      action,
      token,
    })}`;
  } else {
    loginUrl = `${URL_LOCAL}/app/auth?state=${JSON.stringify({
      site: 'admin',
      redirect,
      action,
      token,
    })}`;
  }

  window.location.assign(loginUrl);
}

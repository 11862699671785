import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { Feed } from '../@types/Feed';

interface Props extends Feed {
  title: string;
}

export function useInfiniteFeed({
  title,
  feed: initialFeed,
  next: initialNextUrl,
}: Props) {
  const { data, isLoading, hasNextPage, fetchNextPage } =
    useInfiniteQuery<Feed>(
      `feed-${title}`,
      async ({ pageParam }) => {
        const { data } = await axios.get<Feed>(pageParam || initialNextUrl);

        return data;
      },
      {
        enabled: Boolean(initialNextUrl),
        getNextPageParam(lastPage) {
          return lastPage.next;
        },
      },
    );

  if (data) {
    const feeds = data.pages.map(({ feed }) => feed).flat();

    return {
      data: initialFeed.concat(feeds),
      isLoading,
      hasNextPage,
      fetchNextPage,
    };
  }

  return { data: initialFeed, isLoading, hasNextPage, fetchNextPage };
}

import { PropsWithChildren } from 'react';
import { AccessLevel } from '../@types/access-level';
import { useUser } from '../contexts/UserContext';
import { ScreenPay } from './ScreenPay';

interface Props {
  minAccessLevel?: AccessLevel;
}

export function ScreenLoggingIn({
  children,
  minAccessLevel,
}: PropsWithChildren<Props>) {
  const { isLoading, isLoggedIn, loginError, state: user } = useUser();

  if (isLoading) {
    return <div>Logging in...</div>;
  }

  if (!isLoggedIn) {
    return <div>Login by pressing the buttom in the top right</div>;
  }

  if (!user) {
    console.error(loginError);
    return <div>Sorry there was an issue logging in. Try again later...</div>;
  }

  if (minAccessLevel !== undefined) {
    const accessLevelName =
      user.accessLevel === undefined ? 'Subscriber' : user.accessLevel;
    const accessLevel = AccessLevel[accessLevelName];

    if (accessLevel < minAccessLevel) {
      if (minAccessLevel === AccessLevel.SubscriberPaid) {
        return <ScreenPay />;
      }

      return <div>Sorry you can't access this page</div>;
    }
  }

  return <>{children}</>;
}

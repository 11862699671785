import { useCallback, useState, useEffect, ReactNode } from 'react';
import { useDropzone, FileRejection, ErrorCode } from 'react-dropzone';
import { Upload } from 'phosphor-react';
import { Spinner } from './Spinner';
import { classNames } from '../utils/classNames';

interface Props {
  isLoading: boolean;
  onDrop(file: File): void;
}

export function DropZone({ isLoading, onDrop }: Props) {
  const [error, setError] = useState<ReactNode | null>(null);

  const handleDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const [acceptedFile] = acceptedFiles;
      const [rejection] = rejectedFiles;

      if (rejection) {
        const [error] = rejection.errors;

        switch (error.code) {
          case ErrorCode.FileInvalidType:
            setError(<>Sorry. Currently you can only drag and drop MP4s</>);
            break;

          case ErrorCode.TooManyFiles:
            setError(
              <>
                Sorry. Try dropping a single MP4.
                <br />
                We don't accept multiple files right now
              </>,
            );
            break;

          default:
            setError('An unknown error ocurred');
            break;
        }

        return;
      }

      onDrop(acceptedFile);
    },
    [onDrop],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['video/mp4', 'video/quicktime'],
    multiple: false,
    disabled: isLoading,
    onDrop: handleDrop,
  });

  useEffect(() => {
    if (isDragActive) {
      setError(null);
    }
  }, [isDragActive]);

  return (
    <>
      <div
        {...getRootProps()}
        className={classNames('aspect-w-16 aspect-h-9 border border-dashed', {
          'gradient-to-b border-slate-200 from-white to-slate-100':
            isDragActive,
          'gradient-to-b border-gray-500 from-slate-200 to-slate-400':
            !isDragActive,
        })}
      >
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="h-8 w-8">
            {isLoading ? (
              <Spinner />
            ) : (
              <Upload
                className={classNames('h-full w-full', {
                  'text-red-400': Boolean(error),
                })}
                weight="light"
              />
            )}
          </div>
          {!error && !isLoading && (
            <h4 className="block text-center">
              Drop a video to upload
              <br />
              or
              <br />
              Select a video
            </h4>
          )}
          {error && (
            <h4 className="block text-center text-red-400">
              {error}
              <br />
              Try again...
            </h4>
          )}
        </div>
        <input
          {...getInputProps()}
          type="file"
          className="z-5 absolute block h-full w-full cursor-pointer opacity-0"
        />
      </div>
    </>
  );
}

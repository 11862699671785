import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { getApiUrl } from '../utils/getUrl';
import { useApiHeaders } from './useApiHeaders';
import { ChannelDetails } from '../@types/ChannelDetails';

export function useGetChannelDetails(channelId?: string) {
  const headers = useApiHeaders();

  const { isLoading, isIdle, data, error } = useQuery<
    ChannelDetails | null,
    AxiosError
  >(
    ['channel-details', channelId],
    async () => {
      if (!channelId) {
        return null;
      }

      const { data } = await axios.get(
        getApiUrl(`/channel-details/${channelId}`),
        {
          headers,
        },
      );

      return data;
    },
    {
      enabled: Boolean(headers),
    },
  );

  return {
    isLoading: isLoading || isIdle,
    data,
    error,
  };
}

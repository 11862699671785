import { SkeletonBase } from './SkeletonBase';

interface SkeletonLineProps {
  className: string;
}

export function SkeletonLineTitle({ className }: SkeletonLineProps) {
  return <SkeletonBase className={`${className} h-9`} />;
}

export function SkeletonLine({ className }: SkeletonLineProps) {
  return <SkeletonBase className={`${className} h-4`} />;
}

export function SkeletonLineSmall({ className }: SkeletonLineProps) {
  return <SkeletonBase className={`${className} h-3`} />;
}

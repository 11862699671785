export const ROUTE_VIDEO_CREATE = '/video/create';
export const ROUTE_VIDEO = '/video/:videoId';
export const ROUTE_VIDEOS = '/edit-videos';
export const ROUTE_EDIT_VIDEO = '/edit-videos/:videoId';
export const ROUTE_SUB_COMPLETE = '/subscription/complete';
export const ROUTE_SUB_CANCELLED = '/subscription/cancelled';
export const ROUTE_EDIT_PROFILE = '/profile';
export const ROUTE_PROFILE = '/profile/:userId';

export function videoRoute(id: string) {
  return ROUTE_VIDEO.replace(':videoId', id);
}

export function userRoute(uid: string) {
  return ROUTE_PROFILE.replace(':userId', uid);
}

export function editVideoRoute(id: string) {
  return ROUTE_EDIT_VIDEO.replace(':videoId', id);
}

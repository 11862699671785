import { X } from 'phosphor-react';
import { classNames } from '../utils/classNames';

interface Props {
  value: string;
  onClose?(): void;
}

const Colours = {
  Red: 0,
  Blue: 1,
  Green: 2,
  Purple: 3,
};
const NUM_COLOURS = Object.keys(Colours).length;

export function Tag({ value, onClose }: Props) {
  const color = (value.charCodeAt(0) + value.charCodeAt(1)) % NUM_COLOURS;

  return (
    <div
      className={classNames(
        'leading-sm flex max-w-full cursor-pointer select-none items-center gap-2 rounded-lg p-2 font-bold',
        'transition-colors duration-200',
        {
          'bg-rose-500': color === Colours.Red,
          'text-rose-300': color === Colours.Red,
          'hover:text-red-100': color === Colours.Red,
          'bg-blue-500': color === Colours.Blue,
          'text-blue-300': color === Colours.Blue,
          'hover:text-blue-100': color === Colours.Blue,
          'bg-lime-500': color === Colours.Green,
          'text-lime-300': color === Colours.Green,
          'hover:text-green-100': color === Colours.Green,
          'bg-purple-500': color === Colours.Purple,
          'text-purple-300': color === Colours.Purple,
          'hover:text-purple-100': color === Colours.Purple,
        },
      )}
      onClick={onClose}
    >
      <div className="grow-0 overflow-hidden">{value}</div>
      <div className="grow-1">
        <X weight="bold" />
      </div>
    </div>
  );
}

import { CreatorCampLogo } from './Logo/CreatorCampLogo';

export function Logo() {
  // fill-sky-800
  return (
    <div className="flex h-16 w-16 items-center justify-center fill-slate-800 md:h-20 md:w-20">
      <CreatorCampLogo className="h-16 w-16 md:h-20 md:w-20" />
    </div>
  );
}

import { InputHTMLAttributes, useState } from 'react';
import { Tag } from './Tag';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  tags?: string[];
  onTags?(tags: string[]): void;
}

function formatTag(value: string): string {
  return value
    .toLowerCase()
    .replace(/[^a-z0-9+ ]/g, '')
    .split(' ')
    .filter(Boolean)
    .join(' ');
}

export function TagInput(props: Props) {
  const { tags: propTags, onTags, maxLength, ...restProps } = props;
  const [value, setValue] = useState<string>('');
  const [tags, setTags] = useState<string[]>(propTags ? propTags : []);

  function addTag() {
    if (value.trim().length < 2) {
      return;
    }

    const formattedTag = formatTag(value);

    if (tags.includes(formattedTag)) {
      setValue('');
      return;
    }

    const newTags = [...tags, formatTag(value)].sort();

    setTags(newTags);
    setValue('');
    if (onTags) onTags(newTags);
  }

  return (
    <div className="rounded-md bg-slate-100 p-2 text-sm shadow-inner shadow-slate-200">
      <div className="flex flex-wrap gap-2">
        {tags.map((value) => {
          return (
            <Tag
              onClose={() => {
                const temp = [...tags];
                temp.splice(temp.indexOf(value), 1).sort();
                setTags(temp);
                if (onTags) onTags(temp);
              }}
              key={value}
              value={value}
            />
          );
        })}
        <div className="leading-sm flex w-52 font-bold uppercase">
          <input
            {...restProps}
            type="text"
            value={value}
            className="flex border-transparent bg-transparent p-0 placeholder:text-slate-400 focus:border-transparent focus:ring-0"
            placeholder="+ Add a tag"
            onKeyDown={(ev) => {
              const formattedTag = formatTag(value);

              if (ev.key === 'Enter') {
                addTag();

                return;
              }

              if (maxLength && formattedTag.length === maxLength) {
                ev.preventDefault();
              }

              if (!/[a-z0-9+ ]/.test(ev.key)) {
                ev.preventDefault();
              }
            }}
            onChange={(ev) => {
              setValue(ev.currentTarget.value.trimStart());
            }}
            onBlur={() => {
              addTag();
            }}
          />
        </div>
      </div>
    </div>
  );
}

import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { User } from '../@types/user';
import { getApiUrl } from '../utils/getUrl';
import { handleError } from './handleError';
import { useApiHeaders } from './useApiHeaders';

type UpdateProfile = Omit<User, 'uid' | 'emailVerified' | 'channelSize'>;

export function useUpdateProfile() {
  const headers = useApiHeaders();
  const client = useQueryClient();

  const { isLoading, error, mutateAsync } = useMutation<
    void,
    Error,
    UpdateProfile
  >(
    async (updateData: UpdateProfile) => {
      if (!updateData) {
        throw new Error('cannot be undefined');
      }

      try {
        await axios.patch<undefined>(getApiUrl('/users/profile'), updateData, {
          headers,
        });

        return;
      } catch (error) {
        handleError(error);
      }
    },
    {
      onSuccess: () => {
        client.invalidateQueries('my-profile');
      },
    },
  );

  return { isLoading, error, update: mutateAsync };
}

interface Props {
  date: Date | number;
}

export function FormatDate({ date: dateProp }: Props) {
  let date: Date;

  if (typeof dateProp === 'number') {
    date = new Date(dateProp);
  } else {
    date = dateProp;
  }

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour24 = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const hour = hour24 > 12 ? hour24 - 12 : hour24;
  const amPm = hour24 > 12 ? 'pm' : 'am';

  return <>{`${month}/${day}/${year} ${hour}:${minutes}${amPm}`}</>;
}

import { PropsWithChildren, Children, useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Video as VideoType } from '../@types/video';
import { Video } from '../components/Video';
import { editVideoRoute } from '../routes';
import { CreateVideo } from './ScreenCreateVideo/CreateVideo';
import { ProcessVideo } from './ScreenCreateVideo/ProcessVideo';
import { UploadVideo } from './ScreenCreateVideo/UploadVideo';

type VideoAndFile = VideoType & {
  file: File;
};

function VideoInfo(video: VideoAndFile) {
  return (
    <div>
      <ul>
        <li>id: {video.id}</li>
        <li>status: {video.status}</li>
      </ul>
    </div>
  );
}

function VideoAndInfoContainer({ children }: PropsWithChildren<{}>) {
  const childrenArray = Children.toArray(children);

  return (
    <div className="flex flex-col gap-4 px-4 md:flex-row">
      <div className="md:basis-3/4">{childrenArray[0]}</div>
      <div className="md:basis-1/4">{childrenArray[1]}</div>
    </div>
  );
}

export function ScreenCreateVideo() {
  const [video, setVideo] = useState<VideoAndFile | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const handleCreateComplete = useCallback<
    (videoAndfile: [VideoType, File]) => void
  >(
    ([newVideo, newFile]) => {
      setVideo({ ...newVideo, file: newFile });
    },
    [setVideo],
  );

  const handleUploadComplete = useCallback(() => {
    if (!video) {
      throw new Error('no video on upload complete');
    }

    setVideo({
      ...video,
      status: 'processing',
    });
  }, [setVideo, video]);

  const handleProcessComplete = useCallback(
    (newVideo) => {
      if (!video) {
        throw new Error('no video on process complete');
      }

      setVideo(newVideo);
    },
    [setVideo, video],
  );

  if (error) {
    return <div>There was an error while creating the video</div>;
  }

  switch (video?.status) {
    case undefined:
      return (
        <VideoAndInfoContainer>
          <CreateVideo onComplete={handleCreateComplete} onError={setError} />
        </VideoAndInfoContainer>
      );

    case 'created':
      return (
        <>
          <VideoAndInfoContainer>
            <Video status={video.status} />
            <VideoInfo {...video} />
          </VideoAndInfoContainer>
          <UploadVideo
            file={video.file}
            bucketName={video.bucketName}
            filePath={video.filePath}
            onComplete={handleUploadComplete}
            onError={setError}
          />
        </>
      );

    case 'processing':
      return (
        <>
          <VideoAndInfoContainer>
            <Video status={video.status} />
            <VideoInfo {...video} />
          </VideoAndInfoContainer>
          <ProcessVideo
            id={video.id}
            onComplete={handleProcessComplete}
            onError={setError}
          />
        </>
      );

    case 'processing_error':
      return (
        <VideoAndInfoContainer>
          <Video status={video.status} />
          <VideoInfo {...video} />
        </VideoAndInfoContainer>
      );

    case 'unpublished':
      return <Navigate to={editVideoRoute(video.id)} />;

    default:
      throw new Error(`status: ${video?.status} is not handled`);
  }
}

import { PropsWithChildren } from 'react';
import { VideoCamera, User, SignOut, Icon, FileVideo } from 'phosphor-react';

interface ButtonProps {
  onClick?(): void;
}

interface UserMenuButtonProps extends ButtonProps {
  Icon: Icon;
}

function UserMenuButton({
  children,
  Icon,
  onClick,
}: PropsWithChildren<UserMenuButtonProps>) {
  return (
    <button
      className="flex w-full items-center gap-3 rounded-md p-2 text-gray-700 hover:bg-slate-300 hover:text-white focus:outline-none"
      onClick={onClick}
    >
      <Icon weight="bold" className="h-4 w-4" />
      {children}
    </button>
  );
}

export function VideosButton(props: ButtonProps) {
  return (
    <UserMenuButton {...props} Icon={VideoCamera}>
      Edit Videos
    </UserMenuButton>
  );
}

export function CreateVideoButton(props: ButtonProps) {
  return (
    <UserMenuButton {...props} Icon={FileVideo}>
      Create Video
    </UserMenuButton>
  );
}

export function SignOutButton(props: ButtonProps) {
  return (
    <UserMenuButton {...props} Icon={SignOut}>
      Signout
    </UserMenuButton>
  );
}

export function ProfileButton(props: ButtonProps) {
  return (
    <UserMenuButton {...props} Icon={User}>
      Edit Profile
    </UserMenuButton>
  );
}

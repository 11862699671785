import { useState } from 'react';
import { Image } from './Image';
import { Loader } from './Loader';

interface Props {
  isLoading?: boolean;
  displayName?: string;
  profileImageUrl?: string;
  loaderWidth?:
    | 'w-2'
    | 'w-4'
    | 'w-8'
    | 'w-12'
    | 'w-1/2'
    | 'w-1/4'
    | 'w-1/6'
    | 'w-1/12';
  border?: boolean;
  onLoad?(): void;
}

function ProfileNoImage({ displayName }: Pick<Props, 'displayName'>) {
  let chars = '';

  if (displayName) {
    const char1 = displayName[0].toUpperCase();
    const char2 = displayName.length > 1 ? displayName[1].toLowerCase() : '';
    chars = char1 + char2;
  }

  return <div className="w-full">{chars}</div>;
}

export function ProfileImage({
  isLoading,
  displayName: username,
  profileImageUrl,
  loaderWidth = 'w-1/2',
  border,
  onLoad,
}: Props) {
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const hasBorder = border === undefined || border;

  return (
    <div
      id="profile-image"
      className={`w-full select-none overflow-hidden rounded-full bg-slate-700 ${
        hasBorder ? 'border-moonRiseLightYellow border border-solid' : ''
      }`}
    >
      <div className="aspect-1">
        {isLoading && <Loader width={loaderWidth} />}
        {!isLoading && profileImageUrl && (
          <Image
            src={profileImageUrl}
            alt={username || 'Profile image'}
            errorBgColor="bg-slate-700"
            loaderWidth={loaderWidth}
            objectContain={'object-cover'}
            onLoad={onLoad}
            onError={() => {
              setHasErrored(true);
            }}
          />
        )}
        {!isLoading && (!profileImageUrl || hasErrored) && (
          <ProfileNoImage displayName={username} />
        )}
      </div>
    </div>
  );
}

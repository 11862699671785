import { SkeletonBase } from '../../components/SkeletonBase';
import {
  SkeletonButton,
  SkeletonRadioInput,
  SkeletonTextArea,
  SkeletonTextInput,
} from '../../components/SkeletonInput';

export function SkeletonScreenEditProfile() {
  return (
    <div className="flex max-w-screen-lg flex-row gap-8 p-4">
      <div className="w-2/12">
        <SkeletonBase className="aspect-1" />
      </div>
      <div className="flex-grow">
        <div>
          <SkeletonTextInput />
        </div>
        <div className="mt-4">
          <SkeletonTextArea />
        </div>
        <div className="mt-4">
          <SkeletonTextInput />
        </div>
        <div className="mt-4">
          <SkeletonTextInput />
        </div>
        <div className="mt-4">
          <SkeletonTextInput />
        </div>
        <div className="mt-4">
          <SkeletonRadioInput />
        </div>
        <div className="mt-4 flex justify-end">
          <SkeletonButton />
        </div>
      </div>
    </div>
  );
}

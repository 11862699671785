import axios from 'axios';
import { ValidationResponse } from '../@types/validation-errors';
import { BadRequestException } from '../exceptions/BadRequestException';
import { ClientErrorException } from '../exceptions/ClientErrorException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { NotFoundException } from '../exceptions/NotFoundException';
import { ServerErrorException } from '../exceptions/ServerErrorException';
import { UnAuthorizedException } from '../exceptions/UnAuthorizedException';

export function handleError(error: unknown) {
  if (!axios.isAxiosError(error) || !error.response) {
    throw error;
  }

  const status = error.response.status;
  const data = error.response.data;

  if (status < 400) {
    return;
  }

  switch (status) {
    case 400:
      throw new BadRequestException(data as ValidationResponse);

    case 404:
      throw new NotFoundException();

    case 401:
      throw new UnAuthorizedException();

    case 403:
      throw new ForbiddenException();
  }

  if (status >= 400 && status < 500) {
    throw new ClientErrorException(status);
  }

  if (status >= 500 && status < 600) {
    throw new ServerErrorException(status);
  }
}
